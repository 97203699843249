import { FC } from 'react';
import { CategorisationFields } from 'components/assets/asset-fields/categorisation';
import { LocationField } from 'components/assets/asset-fields/location';
import { TagsFields } from 'components/assets/asset-fields/tags';
import { StatusField } from 'components/assets/asset-fields/status';
import { ProjectField } from 'components/assets/asset-fields/project';
import { AssetValueField } from './asset-value';

interface IAssetFieldsProps {
    displayFields: any;
    projectId: string;
    projectName: string;
    handleRemoveProject: () => void;
    handleCategorisationChange: (categorisation: { subject: string, format: string, product: string }) => void;
    handleLocationChange: (location: string) => void;
    handleStatusChange: (status: string) => void;
    handleTagsChange: (tags: string[]) => void;
    categorisation?: { subject: string, format: string, product: string };
    location?: string;
    status?: string;
    tags?: string[];
    assetValue?: string;
    handleAssetValueChange: (assetValue: string) => void;
}

export const AssetFields: FC<IAssetFieldsProps> = ({
    displayFields,
    projectId,
    projectName,
    handleRemoveProject,
    handleCategorisationChange,
    handleLocationChange,
    handleStatusChange,
    handleTagsChange,
    categorisation,
    location,
    status,
    tags,
    assetValue,
    handleAssetValueChange
}) => {
    return (
        <>
            <StatusField onStatusChange={handleStatusChange} status={status} />
            <AssetValueField onAssetValueChange={handleAssetValueChange} assetValue={assetValue} />
            {projectId &&
                <ProjectField name={projectName} removeProject={handleRemoveProject} />
            }
            {displayFields['location'] &&
                <LocationField onLocationChange={handleLocationChange} location={location} />
            }
            {displayFields['categorisation'] &&
                <CategorisationFields onCategorisationChange={handleCategorisationChange} categorisation={categorisation} />
            }
            {displayFields['tags'] &&
                <TagsFields onTagsChange={handleTagsChange} tags={tags} />
            }
        </>
    );
};
