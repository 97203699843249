import { FC } from 'react';
import { Checkbox, Stack, Typography } from '@mui/material';

interface IProps {
    onStatusChange: (status: string) => void;
    status?: string;
}

export const StatusField: FC<IProps> = ({ onStatusChange, status }) => {

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onStatusChange(event.target.checked ? 'Active' : 'Inactive');
    };

    return (
        <>
            <Stack direction="row" alignItems={'center'} spacing={1} sx={{ pt: 5 }}>
                <Typography variant="h6" sx={{ mb: 0, minWidth: 100 }}>
                    Status
                </Typography>
                <Stack direction="row" spacing={1} alignItems="center">
                    <Checkbox defaultChecked={status === 'Active'} inputProps={{ 'aria-label': 'Status' }} onChange={handleChange} ></Checkbox>
                    <Typography>Active</Typography>
                </Stack>
            </Stack>
        </>
    );
};