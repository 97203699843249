import { useCallback, useEffect, useState } from 'react';
import { useMounted } from "../use-mounted";
import PeerdwebService from "services/peerdweb/peerdweb-service";
import { Asset } from 'types/assets/asset';

interface AssetsStoreState {
    assets: Asset[];
    assetsCount: number;
}

export const useSuperUsersAssetsStore = () => {
    const isMounted = useMounted();
    const [state, setState] = useState<AssetsStoreState>({
        assets: [],
        assetsCount: 0
    });

    const peerdwebService = new PeerdwebService();

    const handleAssetsGet = useCallback(async () => {
        try {
            const response = await peerdwebService.superAdminGetAssets();

            if (isMounted()) {
                setState({
                    assets: response.data.assets,
                    assetsCount: response.data.total
                });
            }
        } catch (err) {
            console.error(err);
        }
    }, [isMounted]);

    useEffect(() => {
        handleAssetsGet();
    }, []);

    return {
        ...state,
        setState
    };
};

