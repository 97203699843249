import { FC } from 'react';
import { useState } from 'react';
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Stack, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseIcon from '@mui/icons-material/Close';

interface IProps {
    isEnterprise: boolean;
}

const InfoDialogFile: FC<IProps> = ({ isEnterprise }) => {
    const [open, setOpen] = useState(false);

    const handleDialogOpen = () => {
        setOpen(true);
    };

    const handleDialogClose = () => {
        setOpen(false);
    };

    return (
        <>
            <IconButton color='primary' size='large' aria-label='Help' onClick={handleDialogOpen} >
                <InfoOutlinedIcon fontSize='inherit' />
            </IconButton>
            <Dialog open={open} onClose={handleDialogClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <IconButton aria-label="close" onClick={handleDialogClose} sx={{ position: 'absolute', right: 8, top: 8 }}>
                    <CloseIcon />
                </IconButton>
                <DialogTitle>How does Peerdweb work?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Stack spacing={1}>
                            <Typography color="text.secondary" variant="body2">
                                When you use Peerdweb to authenticate the existence of a file, rest assured that the file's content remain <strong>private</strong> and <strong>secure</strong>.
                            </Typography>
                            <Typography color="text.secondary" variant="body2">
                                Our tool prompts you to '<strong>select</strong>' a file, the file itself <strong>never leaves your device</strong>.
                            </Typography>
                            <Typography color="text.secondary" variant="body2">
                                Our system works in your browser to create a unique cryptographic hash of the file. This hash serves as a distinct fingerprint of the file, encapsulating its content without revealing any specifics.
                            </Typography>
                            <Typography color="text.secondary" variant="body2">
                                Once generated, this fingerprint is committed to the blockchain, providing immutable proof of the file's existence at that specific point in time.
                            </Typography>
                            <Typography color="text.secondary" variant="body2">
                                <strong>Remember</strong>: at no point does the actual file get uploaded to Peerdweb nor the blockchain.
                            </Typography>
                            <Typography color="text.secondary" variant="body2">
                                <Chip label="Learn more" component="a" href="https://peerdweb.io/the-peerdweb-process#your-files" target='_blank' clickable color='info' />
                            </Typography>
                        </Stack>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    );
};

export default InfoDialogFile;