import type { ChangeEvent } from 'react';
import { useCallback, useState } from 'react';
import { Box, Chip, Container, Divider, Stack, Tab, Tabs, Typography } from '@mui/material';
import { Seo } from 'components/seo';
import type { Page as PageType } from 'types/page';
import { useAuth } from 'hooks/use-auth';
import { ManageOrganisation } from 'components/organisation/manage-organisation';
import { ManageBilling } from 'components/organisation/manage-billing';
import { ManageOrganisationUsers } from 'components/organisation/manage-organisation-users';
import { ManageSmartNda } from 'components/organisation/manage-smart-nda';
import { ManageSmartAccess } from 'components/organisation/manage-smart-access';

const OrganisationPage: PageType = () => {
    const { getSubscriptionLevel } = useAuth();
    const [currentTab, setCurrentTab] = useState<string>('organisation');
    const isEnterprise = getSubscriptionLevel() === 'ENTERPRISE';

    const handleTabsChange = useCallback((event: ChangeEvent<{}>, value: string): void => {
        setCurrentTab(value);
    }, []);

    return (
        <>
            <Seo title="Dashboard: Organisation" />
            <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
                <Container maxWidth="xl">
                    <Stack spacing={3} sx={{ mb: 3 }}>
                        <Typography variant="h4">
                            Organisation Settings <Chip label={getSubscriptionLevel()} color='info' />
                        </Typography>
                        <Divider />
                        <div>
                            <Tabs indicatorColor="primary" allowScrollButtonsMobile onChange={handleTabsChange} scrollButtons="auto" textColor="primary" value={currentTab} variant="scrollable">
                                <Tab key='organisation' label='Organisation' value='organisation' />
                                <Tab key='users' label='Users' value='users' />
                                <Tab key='smart-nda' label='Smart NDA' value='smart-nda' />
                                {/* <Tab key='smart-access' label='Smart Access' value='smart-access' /> */}
                                <Tab key='billing' label='Billing' value='billing' />
                            </Tabs>
                            <Divider />
                        </div>
                    </Stack>

                    {currentTab === 'organisation' && (
                        <ManageOrganisation />
                    )}

                    {currentTab === 'users' && (
                        <ManageOrganisationUsers isEnterprise={isEnterprise} />
                    )}

                    {currentTab === 'smart-nda' && (
                        <ManageSmartNda isEnterprise={isEnterprise} />
                    )}

                    {currentTab === 'smart-access' && (
                        <ManageSmartAccess isEnterprise={isEnterprise} />
                    )}

                    {currentTab === 'billing' && (
                        <ManageBilling />
                    )}
                </Container>
            </Box>
        </>
    );
};

export default OrganisationPage;
