import { FC, useEffect, useState } from 'react';
import { Stack, TextField, Typography } from '@mui/material';

interface IProps {
    onCategorisationChange: (categorisation: { subject: string, format: string, product: string }) => void;
    categorisation?: { subject: string, format: string, product: string };
}

export const CategorisationFields: FC<IProps> = ({ onCategorisationChange, categorisation }) => {

    const [currentCategorisation, setCurrentCategorisation] = useState<{ subject: string, format: string, product: string }>(categorisation ?? { subject: '', format: '', product: '' });

    useEffect(() => {
        onCategorisationChange(currentCategorisation);
    }, [currentCategorisation]);

    const handleChange = (key: string, value: string) => {
        setCurrentCategorisation(prev => ({
            ...prev,
            [key]: value
        }));
    };

    return (
        <>
            <Stack spacing={1} sx={{ pt: 5 }}>
                <Typography variant="h6" sx={{ mb: 0 }}>
                    Categorisation
                </Typography>
                <Stack direction={{ xs: "column", sm: "row" }} spacing={3} alignItems={{ xs: "", sm: "center" }}>
                    <TextField
                        label="Subject"
                        sx={{ flexGrow: 1 }}
                        value={currentCategorisation.subject}
                        onChange={(e) => handleChange('subject', e.target.value)}
                    />
                    <TextField
                        label="Format"
                        sx={{ flexGrow: 1 }}
                        value={currentCategorisation.format}
                        onChange={(e) => handleChange('format', e.target.value)}
                    />
                    <TextField
                        label="Product"
                        sx={{ flexGrow: 1 }}
                        value={currentCategorisation.product}
                        onChange={(e) => handleChange('product', e.target.value)}
                    />
                </Stack>
            </Stack>
        </>
    );
};