
export const PeerdwebColours = {
    NeonBlue: '#6262F8',
    NeonBlue1: '#8585FF',
    NeonBlue2: '#B3B3FF',
    NeonBlue3: '#D2D2FF',
    Teal: '#8EEDE6',
    Teal1: '#ABFFF7',
    Teal2: '#D2FFF8',
    Teal3: '#E9FFFD',
    Navy: '#22265D',
    Navy1: '#303EA0',
    Navy2: '#92B1FF',
    Navy3: '#C0D5FF',
    NabtecGreen: '#0AE8BD',
    NabtecGreen1: '#2EEFC7',
    NabtecGreen2: '#69F2D6',
    NabtecGreen3: '#90F7E3',
    NabtecGrey: '#26292E',
    NabtecGrey1: '#2A2F33',
    NabtecGrey2: '#3C4147',
    NabtecGrey3: '#494E56',
};

export const GetRandomPeerdwebColour = () => {
    const colours = Object.values(PeerdwebColours);
    return colours[Math.floor(Math.random() * colours.length)];
}