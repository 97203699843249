import { useCallback, useEffect, useState } from 'react';
import { useMounted } from "../use-mounted";
import PeerdwebService from "services/peerdweb/peerdweb-service";
import { SmartAccess } from 'types/smart-access/smart-access';

interface SmartAccessesStoreState {
    smartAccesses: SmartAccess[];
    smartAccessesCount: number;
}

export const useSuperUserSmartAccessesStore = () => {
    const isMounted = useMounted();
    const [state, setState] = useState<SmartAccessesStoreState>({
        smartAccesses: [],
        smartAccessesCount: 0
    });

    const peerdwebService = new PeerdwebService();

    const handleAssetsGet = useCallback(async () => {
        try {
            const response = await peerdwebService.superAdminGetSmartAccesses();

            if (isMounted()) {
                setState({
                    smartAccesses: response.data.smartAccesses || [],
                    smartAccessesCount: response.data.total || 0
                });
            }
        } catch (err) {
            console.error(err);
        }
    }, [isMounted]);

    useEffect(() => {
        handleAssetsGet();
    }, []);

    return {
        ...state,
        setState
    };
};

