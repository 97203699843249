import type { FC } from 'react';
import { RouteObject, useRoutes } from 'react-router-dom';
import { Helmet } from 'react-helmet-async'; // <head> interaction
import { ThemeProvider } from '@mui/material/styles';
import { SplashScreen } from 'components/splashScreen';
import { SettingsDrawer } from 'components/settings/settings-drawer';
import { Toaster } from 'components/toaster';
import { SettingsConsumer, SettingsProvider } from 'contexts/settings-context';
import { AuthConsumer, AuthProvider } from 'contexts/firebase-context';
import { gtmConfig } from 'config';
import { useNprogress } from 'hooks/use-nprogress';
import { useAnalytics } from 'hooks/devias-unused/use-analytics';
import { routes } from 'routes';
import { createTheme } from 'theme';
import CssBaseline from '@mui/material/CssBaseline';

// Remove if react-quill is not used
import 'react-quill/dist/quill.snow.css';
// // Remove if react-draft-wysiwyg is not used
// import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// // Remove if simplebar is not used
// import 'simplebar-react/dist/simplebar.min.css';
// // Remove if mapbox is not used
// import 'mapbox-gl/dist/mapbox-gl.css';

export const App: FC = () => {
    useAnalytics(gtmConfig);
    useNprogress();

    const element = useRoutes(routes as RouteObject[]);

    return (
        <AuthProvider>
            <AuthConsumer>
                {(auth) => (
                    <SettingsProvider>
                        <SettingsConsumer>
                            {(settings) => {
                                // Prevent theme flicker when restoring custom settings from browser storage
                                if (!settings.isInitialized) {
                                    // return null;
                                }

                                const theme = createTheme({
                                    colorPreset: settings.colorPreset,
                                    contrast: settings.contrast,
                                    direction: settings.direction,
                                    paletteMode: settings.paletteMode,
                                    responsiveFontSizes: settings.responsiveFontSizes
                                });

                                // Prevent guards from redirecting
                                const showSlashScreen = !auth.isInitialized;

                                return (
                                    <ThemeProvider theme={theme}>
                                        <Helmet>
                                            <meta name="color-scheme" content={settings.paletteMode} />
                                            <meta name="theme-color" content={theme.palette.neutral[900]} />
                                        </Helmet>
                                        <CssBaseline />
                                        {
                                            showSlashScreen
                                                ? <SplashScreen />
                                                : (
                                                    <>
                                                        {element}
                                                        {/* <SettingsButton onClick={settings.handleDrawerOpen} /> */}
                                                        <SettingsDrawer
                                                            canReset={settings.isCustom}
                                                            onClose={settings.handleDrawerClose}
                                                            onReset={settings.handleReset}
                                                            onUpdate={settings.handleUpdate}
                                                            open={settings.openDrawer}
                                                            values={{
                                                                colorPreset: settings.colorPreset,
                                                                contrast: settings.contrast,
                                                                direction: settings.direction,
                                                                paletteMode: settings.paletteMode,
                                                                responsiveFontSizes: settings.responsiveFontSizes,
                                                                stretch: settings.stretch,
                                                                layout: settings.layout,
                                                                navColor: settings.navColor
                                                            }}
                                                        />
                                                    </>
                                                )
                                        }
                                        <Toaster />
                                    </ThemeProvider>
                                );
                            }}
                        </SettingsConsumer>
                    </SettingsProvider>
                )}
            </AuthConsumer>
        </AuthProvider>
    );
};

