import type { RouteObject } from 'react-router';
import Error401Page from 'pages/401';
import Error404Page from 'pages/404';
import Error500Page from 'pages/500';
import { authRoutes } from './auth';
import { siteRoutes } from './site';

export const routes: RouteObject[] = [
    ...authRoutes,
    ...siteRoutes,
    {
        path: '401',
        element: <Error401Page />
    },
    {
        path: '404',
        element: <Error404Page />
    },
    {
        path: '500',
        element: <Error500Page />
    },
    {
        path: '*',
        element: <Error404Page />
    }
];
