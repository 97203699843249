import { Card, CardContent, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { Scrollbar } from 'components/scrollbar';
import { usePageView } from 'hooks/use-page-view';
import type { Page as PageType } from 'types/page';
import {  useSuperUsersAssetsStore } from 'hooks/super-admin/useSuperUsersAssetsStore';

const SuperAdminAssets: PageType = () => {
    const allAssets = useSuperUsersAssetsStore();

    usePageView();

    return (
        <Stack spacing={4}>
            <Stack direction="row" justifyContent="space-between" spacing={4}>
                <Stack spacing={1}>
                    <Typography variant="h4">
                        Assets ({allAssets.assetsCount})
                    </Typography>
                </Stack>
            </Stack>

            <Card>
                <CardContent>
                    <Scrollbar sx={{ '& .simplebar-placeholder': { height: '1px !important' } }}>
                        <Table sx={{ minWidth: 400 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        Asset
                                    </TableCell>
                                    <TableCell></TableCell>
                                    <TableCell />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {allAssets.assets?.map((asset, index) => (
                                    <TableRow key={index}>
                                        <TableCell>
                                            <Stack alignItems="center" direction="row" spacing={1}>
                                                <div>
                                                    <Typography variant="body1">
                                                        {asset.headline}
                                                    </Typography>
                                                    <Typography color="text.secondary" variant="body2">
                                                        User: {asset.owner?.displayName ?? ''}
                                                    </Typography>
                                                </div>
                                            </Stack>
                                        </TableCell>
                                        <TableCell>
                                            Created: {asset.createdAt && new Date(asset.createdAt).toLocaleString('en-GB')}<br />
                                            Updated: {asset.updatedAt && new Date(asset.updatedAt).toLocaleString('en-GB')}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Scrollbar>
                </CardContent>
            </Card>
        </Stack>
    );
};

export default SuperAdminAssets;
