import { FC } from 'react';
import { useEffect } from 'react';
import { Card } from '@mui/material';
import { useAuth } from 'hooks/use-auth';
import { useLogger } from 'hooks/use-logger';
import { useAssetsSearch, useAssetStore } from 'hooks/assets/useAssetStore';
import { ViewAssetsEnhancedTable } from './view-assets-enhanced-table';


export const ViewAssets: FC = () => {
    const assetsSearch = useAssetsSearch();
    const assetsStore = useAssetStore(assetsSearch.state);
    const auth = useAuth();
    const [log] = useLogger();

    useEffect(() => {
        log(auth.authUser?.email ?? '', "Asset List");
    }, []);

    useEffect(() => {
        assetsStore.searchAssets();
    }, [assetsSearch.state]);


    return (
        <>
            <Card>
                <ViewAssetsEnhancedTable assets={assetsStore.assets} onFiltersChange={assetsSearch.handleFiltersChange} />
            </Card>
        </>
    );
};

