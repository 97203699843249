import JSZip from "jszip";
import { Accept } from "react-dropzone";

export interface IFilesZipper {
    zipFiles(): Promise<Blob>;
}

export interface IFileConstraints {
    accept: Accept;
    maxSizeMb: number;
    maxFiles: number;
}

export const FileConstraints: IFileConstraints = {
    accept: {
        '*/*': []
        // 'image/*': [],
        // 'text/*': [],
        // 'application/pdf': [],
        // 'application/msword': [],
        // 'application/vnd.ms-excel': [],
        // 'application/zip': ["zip"],
        // 'application/xml': []
    },
    maxSizeMb: 100,
    maxFiles: 1
}

export default class FilesZipper implements IFilesZipper {
    private zip: JSZip;
    private files: File[];
    private _onProgress?: (progress: number) => void;

    constructor(files: File[], onProgress?: (percent: number) => void) {
        this.zip = new JSZip();
        this.files = files;
        this._onProgress = onProgress;
    }

    public async zipFiles(): Promise<Blob> {
        const zip = this.zip;
        const files = this.files;

        for (const file of files) {
            const fileContent = await file.arrayBuffer();

            zip.file(file.name, fileContent, {
                date: new Date(file.lastModified),
            });
        }

        const blob = await zip.generateAsync({
            type: "blob",
            mimeType: `application/${process.env.REACT_APP_PROTECTED_FILE_EXT}`
        },
            (metadata) => {
                if (this._onProgress)
                    this._onProgress(Math.round(metadata.percent));
            });

        return blob;
    }
}