import { ChangeEvent, FC, useEffect } from 'react';
import { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, InputAdornment, SvgIcon, TextField } from '@mui/material';
import PlusIcon from '@untitled-ui/icons-react/build/esm/Plus';
import SearchIcon from '@mui/icons-material/Search';
import { debounce } from 'lodash';


interface IProps {
    onChange?: (name: string) => void;
    onCreate?: (name: string) => void;
}

export const ProjectListSearchCreate: FC<IProps> = ({ onChange, onCreate }) => {
    const headlineRef = useRef<HTMLInputElement | null>(null);
    const [projectName, setProjectName] = useState<string>('');
    const [createProjectEnalbed, setCreateProjectEnabled] = useState<boolean>(false);

    const handleCreateProject = useCallback(() => {
        onCreate?.(projectName);
    }, [onCreate, projectName]);

    const handleTextChange = useCallback(debounce((event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        setProjectName(event.target.value);
        onChange?.(event.target.value);
    }, 500), []);

    useEffect(() => {
        setCreateProjectEnabled(projectName.length > 0);
    }, [projectName]);



    return (

        <TextField
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SvgIcon><SearchIcon /></SvgIcon>
                    </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment position="end">
                        <Button startIcon={(<SvgIcon><PlusIcon /></SvgIcon>)} variant='text' color='info' onClick={handleCreateProject} disabled={!createProjectEnalbed}>
                            Create new project
                        </Button>
                    </InputAdornment>
                ),
                ref: headlineRef
            }}
            label="Search / create new project"
            name="email"
            sx={{ flexGrow: 1, minWidth: 800 }}
            type="text"
            onChange={handleTextChange}
        />

    );
};

ProjectListSearchCreate.propTypes = {
    onChange: PropTypes.func
};
