import { FC } from 'react';
import User01Icon from '@untitled-ui/icons-react/build/esm/User01';
import { Avatar, Card, CardContent, Stack, SvgIcon, Typography, Table, TableHead, TableRow, TableCell, TableBody, Link, Button } from '@mui/material';
import { useAuth } from 'hooks/use-auth';
import { Scrollbar } from 'components/scrollbar';
import { toPrettyUserAccessStatus } from 'utils/pretty-pretty';

interface IProps {
    revoke: (userId?: string) => void;
    invite: (userId?: string) => void;
    smartAccessList: { [userId: string]: any };
}

export const SmartAccessOrganisationUsers: FC<IProps> = ({ revoke, invite, smartAccessList }) => {
    const { organisation, peerdwebUser } = useAuth();

    return (
        <Stack spacing={4}>
            <Card>
                <CardContent>
                    <Stack spacing={3}>
                        <Scrollbar sx={{ '& .simplebar-placeholder': { height: '1px !important' } }}>
                            <Table sx={{ minWidth: 500 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            User
                                        </TableCell>
                                        <TableCell>
                                            Last Logged In
                                        </TableCell>
                                        <TableCell>
                                            Smart Access
                                        </TableCell>
                                        <TableCell>
                                            Actions
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {organisation?.users?.map((user, index) => (
                                        <TableRow key={index}>
                                            <TableCell>
                                                <Stack alignItems="center" direction="row" spacing={1}>
                                                    <Avatar src={user.imageUrl} sx={{ height: 40, width: 40 }}>
                                                        <SvgIcon>
                                                            <User01Icon />
                                                        </SvgIcon>
                                                    </Avatar>
                                                    <div>
                                                        <Typography variant="subtitle2">
                                                            {user.displayName}
                                                        </Typography>
                                                        <Typography color="text.secondary" variant="body2">
                                                            <Link href={`mailto:${user.email}`}>{user.email}</Link>
                                                        </Typography>
                                                    </div>
                                                </Stack>
                                            </TableCell>
                                            <TableCell>
                                                {user.lastLoggedInAt && new Date(user.lastLoggedInAt).toLocaleDateString('en-GB', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
                                            </TableCell>
                                            <TableCell>
                                                <>
                                                    {user._id && toPrettyUserAccessStatus(smartAccessList[user._id]?.status)}
                                                </>
                                                {user._id === peerdwebUser?._id && 'You'}
                                            </TableCell>
                                            <TableCell align="right">
                                                {user._id && user._id !== peerdwebUser?._id && smartAccessList[user._id]?.status === 'invite' &&
                                                    <Button variant="contained" color="warning" onClick={() => { revoke(user._id) }}>
                                                        Revoke Invite
                                                    </Button>
                                                }
                                                {user._id && user._id !== peerdwebUser?._id && smartAccessList[user._id]?.status === 'accept' &&
                                                    <Button variant="contained" color="error" onClick={() => { revoke(user._id) }}>
                                                        Revoke Access
                                                    </Button>
                                                }
                                                {user._id && user._id !== peerdwebUser?._id && smartAccessList[user._id]?.status !== 'invite' && smartAccessList[user._id]?.status !== 'accept' &&
                                                    <Button variant="contained" onClick={() => { invite(user._id) }}>
                                                        Invite Access
                                                    </Button>
                                                }
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Scrollbar>
                    </Stack>
                </CardContent>
            </Card>
        </Stack>
    );
};

