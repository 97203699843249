import { FC } from 'react';
import { MenuItem, Select, SelectChangeEvent, Stack, Typography } from '@mui/material';

interface IProps {
    onLocationChange: (location: string) => void;
    location?: string;
}

export const LocationField: FC<IProps> = ({ onLocationChange, location }) => {

    const handleChange = (event: SelectChangeEvent) => {
        onLocationChange(event.target.value as string);
    };

    return (
        <>
            <Stack direction="row" alignItems={'center'} spacing={1} sx={{ pt: 5 }}>
                <Typography variant="h6" sx={{ mb: 0, minWidth: 100 }}>
                    Location
                </Typography>
                <Stack direction="row" spacing={1} alignItems="center">
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        defaultValue={location ?? ''}
                        sx={{ width: 200 }}
                        onChange={handleChange}
                    >
                        <MenuItem value={''}>&nbsp;</MenuItem>
                        <MenuItem value={'Head Office'}>Head Office</MenuItem>
                        <MenuItem value={'Sydney Office'}>Sydney Office</MenuItem>
                        <MenuItem value={'Edinburgh Lab'}>Edinburgh Lab</MenuItem>
                        <MenuItem value={'Durham Engineering'}>Durham Engineering</MenuItem>
                    </Select>
                </Stack>
            </Stack>
        </>
    );
};