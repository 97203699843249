import crypto from 'crypto';

export class Splash {
    public readonly hash: string;

    public get segments(): string[] {
        return this.getSegments();
    }

    public get token(): string {
        return this.segments.join('.');
    }

    private constructor(hash: string) {
        this.hash = hash;
    }

    public static async fromBlobAsync(blob: Blob) {
        const hash = await Splash.generateHash(blob);
        return new Splash(hash);
    }

    public static async fromHexAsync(text: string) {
        return new Splash(text.replace('0x', ''));
    }

    public static async fromTextAsync(text: string) {
        const blob = new Blob([text]);
        const hash = await Splash.generateHash(blob);
        return new Splash(hash);
    }

    public static async fromUrlAsync(url: string) {
        const response = await fetch(url);
        const blob = await response.blob();
        const hash = await Splash.generateHash(blob);
        return new Splash(hash);
    }

    public static async fromDateAsync(date: Date) {
        return await Splash.fromTextAsync(date.toISOString());
    }

    private static async generateHash(blob: Blob) {
        const buffer = Buffer.from(await blob.arrayBuffer());

        const hash = crypto
            .createHash('sha256')
            .update(buffer)
            .digest('hex');

        return hash;
    }

    private getSegments() {
        const segmentLength = 6;
        const regex = new RegExp(`.{1,${segmentLength}}`, 'g');
        const tokens = this.hash.match(regex);

        if (!tokens)
            return [];

        let lastSegment = tokens[tokens.length - 1];

        if (lastSegment.length < segmentLength) {
            const padding = segmentLength - lastSegment.length;
            lastSegment += lastSegment.substring(0, padding);
            tokens[tokens.length - 1] = lastSegment;
        }

        return tokens as string[];
    }
}

export default Splash;