import { Box, Container, Divider, Stack, Tab, Tabs, Typography } from '@mui/material';
import { Seo } from 'components/seo';
import { useAuth } from 'hooks/use-auth';
import { usePageView } from 'hooks/use-page-view';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import type { Page as PageType } from 'types/page';
import { useLogger } from "hooks/use-logger";
import IncomingSmartAccessList from 'components/smart-access/incoming-smart-access-list';
import OutgoingSmartAccessList from 'components/smart-access/outgoing-smart-access-list';

const SmartAccessPage: PageType = () => {
    const { authUser } = useAuth();
    const [log] = useLogger();
    const [currentTab, setCurrentTab] = useState<string>('others');

    const handleTabsChange = useCallback((event: ChangeEvent<{}>, value: string): void => {
        setCurrentTab(value);
    }, []);

    useEffect(() => {
        log(authUser?.email ?? '', "Smart Access");
    }, []);

    usePageView();

    return (
        <>
            <Seo title="Peerdweb: Smart Access" />
            <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
                <Container maxWidth="xl">
                    <Stack spacing={3} sx={{ mb: 3 }}>
                        <Typography variant="h4">
                            Smart Access
                        </Typography>
                        <Divider />
                        <div>
                            <Tabs indicatorColor="primary" allowScrollButtonsMobile onChange={handleTabsChange} scrollButtons="auto" textColor="primary" value={currentTab} variant="scrollable">
                                <Tab key='others' label='Shared with you' value='others' />
                                <Tab key='yours' label='Your asset shares' value='yours' />
                            </Tabs>
                            <Divider />
                        </div>

                    </Stack>

                    {currentTab === 'others' && (
                        <IncomingSmartAccessList />
                    )}

                    {currentTab === 'yours' && (
                        <OutgoingSmartAccessList />
                    )}
                </Container>
            </Box>
        </>
    );
};

export default SmartAccessPage;
