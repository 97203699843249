export default class Hasher {

    public static async generate(blob: Blob): Promise<string> {
        try {
            const buffer = await blob.arrayBuffer();
            const hashBuffer = await crypto.subtle.digest('SHA-256', buffer);
            const hashArray = Array.from(new Uint8Array(hashBuffer));
            const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
            return hashHex;
        } catch (error) {
            console.error('Error calculating hash:', error);
            throw error;
        }
    }
}