import { Suspense } from 'react';
import type { RouteObject } from 'react-router';
import { Outlet } from 'react-router-dom';
import { Layout as DashboardLayout } from 'layouts/dashboard';
import HomePage from 'pages/index';
import IconsPage from 'pages/icons';
import AccountPage from 'pages/account';
import NewAssetPage from 'pages/assets/new-asset';
import VerifyPage from 'pages/verify/verify';
import ViewAssetPage from 'pages/assets/view-asset';
import { paths } from 'paths';
import SuperAdminPage from 'pages/super-admin';
import SmartAccessPage from 'pages/smart-access';
import OrganisationPage from 'pages/organisation';
import AssetListTestPage from 'pages/assets/asset-list-v2';
import ProjectListPage from 'pages/projects/project-list';
import ViewProjectPage from 'pages/projects/view-project';
import AnalyticsPage from 'pages/analytics';

export const siteRoutes: RouteObject[] = [
    {
        path: '/',
        element: (
            <DashboardLayout>
                <Suspense>
                    <Outlet />
                </Suspense>
            </DashboardLayout>
        ),
        children: [
            {
                index: true,
                element: <HomePage />
            },
            {
                path: paths.icons,
                element: <IconsPage />
            },
            {
                path: paths.account,
                element: <AccountPage />
            },
            {
                path: paths.organisation,
                element: <OrganisationPage />
            },
            {
                path: paths.assets.new,
                element: <NewAssetPage />
            },
            {
                path: paths.assets.view(':id'),
                element: <ViewAssetPage />
            },
            {
                path: paths.assets.list,
                element: <AssetListTestPage />
            },
            {
                path: paths.projects.list,
                element: <ProjectListPage />
            },
            {
                path: paths.projects.view(':id'),
                element: <ViewProjectPage />
            },
            {
                path: paths.verify,
                element: <VerifyPage />
            },
            {
                path: paths.superAdmin.list,
                element: <SuperAdminPage />
            },
            {
                path: paths.smartAccess,
                element: <SmartAccessPage />
            },
            {
                path: paths.analytics,
                element: <AnalyticsPage />,
            },
            // {
            //     path: 'peers/list',
            //     element: <PeersList />
            // },
        ],
    },
];
