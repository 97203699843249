import { FC, useEffect, useState } from 'react';
import { Fade, Grid, IconButton, Snackbar, Typography } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Splash from 'utils/splash';

interface IProps {
    hash: string;
}

const SplashDisplay: FC<IProps> = ({ hash }) => {
    const [snackbarState, setSnackbarState] = useState<boolean>(false);

    const handleClose = () => {
        setSnackbarState(false);
    };

    const [splash, setSplash] = useState<Splash>();

    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(hash);
        setSnackbarState(true);
    }

    const generateSplash = async (hexData: string) => {
        const splash = await Splash.fromHexAsync(hexData);
        setSplash(splash);
    }

    useEffect(() => {
        generateSplash(hash);
    }, [hash]);


    return (
        <>
            <Snackbar
                open={snackbarState}
                onClose={handleClose}
                TransitionComponent={Fade}
                message="Copied to clipboard"
                autoHideDuration={1800}
            />
            <Grid container id="splash-output-container" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', justifyContent: 'center' }}>
                <Grid item sx={{ display: 'flex', alignItems: 'left' }}>
                    {/* <Typography variant="body2" sx={{ fontWeight: 500, fontSize: "18px", color: '#1E2326' }}> */}
                        <div title={splash?.hash} style={{ display: 'flex', alignItems: 'center', width: 300 }}>
                            {
                                splash?.segments?.slice(0, 5).map((segment, index) => (
                                    <div key={index} style={{
                                        backgroundColor: `#${segment}`,
                                        width: '26px',
                                        height: '26px',
                                        border: '0px solid',
                                        borderColor: '#000',
                                        borderRadius: '2px',
                                        marginRight: '5px'
                                    }}>
                                        &nbsp;
                                    </div>

                                    // my design, too "techie" for Whits:
                                    // <div key={index} style={{
                                    //     backgroundColor: `#${segment}`,
                                    //     width: '30px',
                                    //     height: '30px',
                                    //     borderTop: '3px solid',
                                    //     borderBottom: '3px solid',
                                    //     borderColor: '#1E2326',
                                    //     borderTopRightRadius:  index === 6 ? '5px' : undefined,
                                    //     borderBottomRightRadius:  index === 6 ? '5px' : undefined,
                                    //     borderTopLeftRadius:  index === 0 ? '5px' : undefined,
                                    //     borderBottomLeftRadius:  index === 0 ? '5px' : undefined,
                                    //     marginRight: '0px',
                                    //     borderLeft: index === 0 ? '3px solid' : '2px solid',
                                    //     borderRight: index === 6 ? '3px solid' : undefined
                                    // }}>
                                    //     &nbsp;
                                    // </div>
                                ))
                            }
                            <IconButton onClick={handleCopyToClipboard}>
                                <ContentCopyIcon />
                            </IconButton>
                        </div>
                    {/* </Typography> */}
                </Grid>
            </Grid>
        </>
    );
};

export default SplashDisplay;