import { ApexOptions } from "apexcharts"
import { PeerdwebColours } from "theme/peerdweb-colours";

const useChartOptions = (): ApexOptions => {
    return {
        tooltip: {
            enabled: true,
        },
        dataLabels: {
            enabled: false,
        },
        chart: {
            background: "transparent",
            animations: {
                enabled: true,
                easing: "easein",
                speed: 400,
                animateGradually: {
                    enabled: true,
                    delay: 150,
                },
                dynamicAnimation: {
                    enabled: false,
                },
            },
            type: "bar",
            toolbar: {
                show: false,
            }
        },
        plotOptions: {
            bar: {
                borderRadius: 5,
                barHeight: '40px',
                horizontal: true,
            },
        },
        colors: [
            PeerdwebColours.Navy2,
        ],
        fill: {
            opacity: 1,
            type: "solid",
        },
        grid: {
            show: true,
            strokeDashArray: 2,
            padding: {
                bottom: 0,
                left: 0,
                right: 0,
                top: 0,
            },
            xaxis: {
                lines: {
                    show: true,
                }
            },
            yaxis: {
                lines: {
                    show: false,
                }
            }
        },
        xaxis: {
            axisBorder: {
                show: false,
            },
            labels: {
                show: true,
                style: {
                    colors: PeerdwebColours.Navy1,
                },
            }
        },
        yaxis: {
            labels: {
                show: true,
                align: "left",
                padding: 4,
                offsetX: -10,
            }
        },
    }
}

export default useChartOptions;