import { useState } from 'react';
import { useMounted } from 'hooks/use-mounted';
import PeerdwebService from 'services/peerdweb/peerdweb-service';
import { SmartAccessActivityLog, SmartAccessActivityLogSearchRequest } from 'types/smart-access/smart-access-activity';

export const useSmartAccessActivityLogStore = () => {
    const isMounted = useMounted();
    const [state, setState] = useState<SmartAccessActivityLog[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const peerdwebService = new PeerdwebService();

    const searchSmartAccessActivityLog = async (searchRequest: SmartAccessActivityLogSearchRequest) => {
        try {
            setIsLoading(true);
            setState([]);

            const response = await peerdwebService.searchSmartAccessActivityLogs(searchRequest);
            if (isMounted()) {
                setState(response.data as SmartAccessActivityLog[]);
            }
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    };

    return {
        state,
        isLoading,
        searchSmartAccessActivityLog
    };
};