import { FC } from 'react';
import { useEffect } from 'react';
import { Alert, Divider, Paper, Toolbar, Typography } from '@mui/material';
import { ViewProjectsSearch } from './view-projects-search';
import { ViewProjectsTable } from './view-projects-table';
import { useAuth } from 'hooks/use-auth';
import { useLogger } from 'hooks/use-logger';
import { Box, Stack } from '@mui/system';
import { useProjectsSearch, useProjectsStore } from 'hooks/projects/useProjectsStore';

export const ViewProjects: FC = () => {
    const { organisation, authUser } = useAuth();
    const projectsSearch = useProjectsSearch(organisation?._id ?? '');
    const projectsStore = useProjectsStore(projectsSearch.state);
    const [log] = useLogger();

    useEffect(() => {
        log(authUser?.email ?? '', "Project List");
    }, []);

    return (
        <Stack spacing={2} sx={{ minHeight: 550 }}>
            <Box>
                <Paper sx={{ width: '100%', mb: 2 }}>
                    <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 }, }}>
                        <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div">
                            <ViewProjectsSearch onChange={projectsSearch.handleSearchChange} />
                        </Typography>
                    </Toolbar>
                    <Divider />
                    {projectsStore.errorMessage &&
                        <Alert variant="filled" severity="error">
                            {projectsStore.errorMessage}
                        </Alert>
                    }
                    <Box>
                        <ViewProjectsTable
                            onPageChange={projectsSearch.handlePageChange}
                            onRowsPerPageChange={projectsSearch.handleRowsPerPageChange}
                            page={projectsSearch.state.page}
                            items={projectsStore.projects}
                            count={projectsStore.projectsCount}
                            pageSize={projectsSearch.state.pageSize}
                        />
                    </Box>
                </Paper>
            </Box>
        </Stack>
    );
};

