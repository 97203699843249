import { FC, useEffect } from 'react';
import { useCallback, useState } from 'react';
import { Card, CardContent, FormHelperText, Grid, Stack, Typography } from '@mui/material';
import { SelectFileDropzone } from 'components/assets/new-asset/select-file-dropzone';
import type { File } from 'components/assets/new-asset/select-file-dropzone';
import { ErrorMessage, useFormikContext } from 'formik';
import { NewAssetValues } from "types/assets/asset";
import { FileConstraints } from 'core/files-zipper';
import InfoDialogFile from './info-dialog-file';

interface IProps {
    isEnterprise: boolean;
}

export const NewAssetSelectFile: FC<IProps> = ({ isEnterprise }) => {
    const formik = useFormikContext<NewAssetValues>();
    const [files, setFiles] = useState<File[]>(formik.values.files);

    const handleFilesDrop = useCallback((newFiles: File[]): void => {
        // always a single file, overwrite the previous one
        setFiles(() => {
            return [...newFiles];
        });
    }, []);

    const handleFileRemove = useCallback((file: File): void => {
        setFiles((prevFiles) => {
            return prevFiles.filter((_file) => _file.path !== file.path);
        });
    }, []);

    const handleFilesRemoveAll = useCallback((): void => {
        setFiles([]);
    }, []);

    useEffect(() => {
        formik.setFieldValue('files', files);
    }, [files]);

    return (
        <>
            <Stack>
                <Stack spacing={1}>
                    <Typography variant="h5">
                        File
                        <InfoDialogFile isEnterprise={isEnterprise} />
                    </Typography>

                </Stack>
                <Grid container spacing={3}>
                    <Grid xs={12} md={6} item>
                        <Card>
                            <CardContent>
                                <Grid container spacing={3}>
                                    <Grid xs={12} item>
                                        <SelectFileDropzone
                                            accept={FileConstraints.accept}
                                            caption={`Maximum file size ${FileConstraints.maxSizeMb} MB`}
                                            files={files}
                                            onDrop={handleFilesDrop}
                                            onRemove={handleFileRemove}
                                            onRemoveAll={handleFilesRemoveAll}
                                            maxSize={FileConstraints.maxSizeMb * 1024 * 1024}
                                            maxFiles={FileConstraints.maxFiles}
                                            multiple={false}
                                            noDrag={true}
                                        />
                                        <FormHelperText error>
                                            <ErrorMessage name="files" />
                                        </FormHelperText>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Stack >
        </>
    );
};