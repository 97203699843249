import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Box, ButtonBase } from '@mui/material';
import { RouterLink } from 'components/router-link';
import { PeerdwebColours } from 'theme/peerdweb-colours';

interface MobileNavItemProps {
    active?: boolean;
    children?: ReactNode;
    depth?: number;
    disabled?: boolean;
    external?: boolean;
    icon?: ReactNode;
    label?: ReactNode;
    open?: boolean;
    path?: string;
    title: string;
}

export const MobileNavItem: FC<MobileNavItemProps> = (props) => {
    const {
        active,
        depth = 0,
        disabled,
        external,
        icon,
        label,
        path,
        title
    } = props;

    // Icons can be defined at top level only, deep levels have bullets instead of actual icons.

    let startIcon: ReactNode;

    if (depth === 0) {
        startIcon = icon;
    } else {
        startIcon = (
            <Box
                sx={{
                    alignItems: 'center',
                    display: 'center',
                    height: 20,
                    justifyContent: 'center',
                    width: 20
                }}
            >
                <Box
                    sx={{
                        borderRadius: '50%',
                        backgroundColor: '#9DA4AE',
                        height: 4,
                        opacity: 0, // remove this if you want it to be visible
                        width: 4,
                        ...(active && {
                            backgroundColor: 'var(--nav-item-icon-active-color)',
                            height: 6,
                            opacity: 1,
                            width: 6
                        })
                    }}
                />
            </Box>
        );
    }

    const offset = depth === 0 ? 0 : (depth - 1) * 16;

    const linkProps = path ? external ? { component: 'a', href: path, target: '_blank' } : { component: RouterLink, href: path } : {};

    return (
        <li>
            <ButtonBase
                disabled={disabled}
                sx={{
                    alignItems: 'center',
                    borderRadius: 1,
                    display: 'flex',
                    justifyContent: 'flex-start',
                    pl: `${16 + offset}px`,
                    pr: '16px',
                    py: '6px',
                    textAlign: 'left',
                    width: '100%',
                    ...(active && {
                        ...(depth === 0 && {
                            backgroundColor: '#11192714'
                        })
                    }),
                    '&:hover': {
                        backgroundColor: '#1119270A'
                    }
                }}
                {...linkProps}
            >
                {startIcon && (
                    <Box
                        component="span"
                        sx={{
                            alignItems: 'center',
                            color: '#9DA4AE',
                            display: 'inline-flex',
                            justifyContent: 'center',
                            mr: 2,
                            ...(active && {
                                color: '#6366F1'
                            })
                        }}
                    >
                        {startIcon}
                    </Box>
                )}
                <Box
                    component="span"
                    sx={{
                        color: '#6C737F',
                        flexGrow: 1,
                        fontFamily: (theme) => theme.typography.fontFamily,
                        fontSize: depth > 0 ? 13 : 14,
                        fontWeight: depth > 0 ? 500 : 600,
                        lineHeight: '24px',
                        whiteSpace: 'nowrap',
                        ...(active && {
                            color: PeerdwebColours.Navy
                        }),
                        ...(disabled && {
                            color: PeerdwebColours.Navy
                        })
                    }}
                >
                    {title}
                </Box>
                {label && (
                    <Box
                        component="span"
                        sx={{ ml: 2 }}
                    >
                        {label}
                    </Box>
                )}
            </ButtonBase>
        </li>
    );
};

MobileNavItem.propTypes = {
    active: PropTypes.bool,
    children: PropTypes.node,
    depth: PropTypes.number,
    disabled: PropTypes.bool,
    external: PropTypes.bool,
    icon: PropTypes.node,
    open: PropTypes.bool,
    path: PropTypes.string,
    title: PropTypes.string.isRequired
};
