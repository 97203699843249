import { FC, useEffect } from 'react';
import { useCallback, useRef, useState } from 'react';
import SearchMdIcon from '@untitled-ui/icons-react/build/esm/SearchMd';
import { Box, Chip, Divider, IconButton, Input, Stack, SvgIcon, Tooltip, Typography } from '@mui/material';
import { useUpdateEffect } from 'hooks/use-update-effect';
import { useAuth } from 'hooks/use-auth';
import { debounce } from '@mui/material/utils'
import FilterListIcon from '@mui/icons-material/FilterList';
import { useProjectsSearch, useProjectsStore } from 'hooks/projects/useProjectsStore';
import { Asset } from 'types/assets/asset';
import AssetsFilterDialog from './assets-filter-dialog';
import { FilterOption, SearchChip } from 'types/search/asset-search';
import { AssetStoreSearchFilters } from 'hooks/assets/useAssetStore';

interface IProps {
    assets?: Asset[];
    onFiltersChange: (filters: AssetStoreSearchFilters) => void;
}

// TODO - this boi is getting big
export const ViewAssetsSearch: FC<IProps> = ({ assets, onFiltersChange }) => {
    const searchTextRef = useRef<HTMLInputElement | null>(null);
    const [chips, setChips] = useState<SearchChip[]>([]);
    const [open, setOpen] = useState(false);
    const { organisation } = useAuth();

    const projectsSearch = useProjectsSearch(organisation?._id ?? '');
    const projectsStore = useProjectsStore(projectsSearch.state);

    const [projectOptions, setProjectOptions] = useState<FilterOption[]>([]);
    const [ownerOptions, setOwnerOptions] = useState<FilterOption[]>([]);
    const [statusOptions, setStatusOptions] = useState<FilterOption[]>([]);


    // #region Filters Dialog

    const handleFiltersDialogOpen = () => {
        setOpen(true);
    };

    const handleFiltersDialogClose = () => {
        setOpen(false);
    };

    // #endregion



    // #region Categorisation options

    const subjects = assets?.reduce((acc: { [key: string]: string }, asset) => {
        if (asset.subject && !acc[asset.subject]) {
            acc[asset.subject] = asset.subject;
        }
        return acc;
    }, {});
    const subjectOptions = subjects && Object.keys(subjects).map((subject) => {
        const firstLetter = subject[0].toUpperCase();
        return {
            firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
            title: subject,
        };
    });
    const formats = assets?.reduce((acc: { [key: string]: string }, asset) => {
        if (asset.format && !acc[asset.format]) {
            acc[asset.format] = asset.format;
        }
        return acc;
    }, {});
    const formatOptions = formats && Object.keys(formats).map((format) => {
        const firstLetter = format[0].toUpperCase();
        return {
            firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
            title: format,
        };
    });
    const products = assets?.reduce((acc: { [key: string]: string }, asset) => {
        if (asset.product && !acc[asset.product]) {
            acc[asset.product] = asset.product;
        }
        return acc;
    }, {});
    const productOptions = products && Object.keys(products).map((product) => {
        const firstLetter = product[0].toUpperCase();
        return {
            firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
            title: product,
        };
    });

    // #endregion



    // #region Filters and chips

    const handleChipsUpdate = useCallback(() => {
        const filters: AssetStoreSearchFilters = {
            searchText: undefined,
            project: [],
            status: [],
            owner: [],
        };

        console.log('chips', chips);
        chips.forEach((chip) => {
            switch (chip.field) {
                case 'searchText':
                    // There will (or should) be only one chips with field "headline"
                    // so we can set up it directly
                    filters.searchText = chip.value as string;
                    break;
                case 'project':
                    filters.project?.push(chip.value as string);
                    break;
                case 'status':
                    filters.status?.push(chip.value as string);
                    break;
                case 'owner':
                    filters.owner?.push(chip.value as string);
                    break;
                default:
                    break;
            }
        });

        onFiltersChange?.(filters);
    }, [chips, onFiltersChange]);

    const createChip = (field: SearchChip['field'], value: string, label: string, displayValue?: string): SearchChip => ({
        label,
        field,
        value,
        displayValue: displayValue ?? value
    });

    const handleChipDelete = useCallback((deletedChip: SearchChip): void => {
        setChips((prevChips) => {
            return prevChips.filter((chip) => {
                // There can exist multiple chips for the same field.
                // Filter them by value.

                return !(deletedChip.field === chip.field && deletedChip.value === chip.value);
            });
        });

        if (deletedChip.field === 'searchText') {
            searchTextRef.current!.value = '';
        }

        if (deletedChip.field === 'project') {
            const updatedProjectOptions = projectOptions.map((option) => {
                if (option.value === deletedChip.value) {
                    option.selected = false;
                }
                return option;
            });
            setProjectOptions(updatedProjectOptions);
        }

        if (deletedChip.field === 'owner') {
            const updatedOwnerOptions = ownerOptions.map((option) => {
                if (option.value === deletedChip.value) {
                    option.selected = false;
                }
                return option;
            });
            setOwnerOptions(updatedOwnerOptions);
        }

        if (deletedChip.field === 'status') {
            const updatedStatusOptions = statusOptions.map((option) => {
                if (option.value === deletedChip.value) {
                    option.selected = false;
                }
                return option;
            });
            setStatusOptions(updatedStatusOptions);
        }

    }, [projectOptions]);

    const handleSearchTextChange = useCallback(
        debounce((searchTextQuery: string | null): void => {
            setChips((prevChips) => {
                const newChips = prevChips.filter((chip) => chip.field !== 'searchText');

                if (!searchTextQuery || searchTextQuery === '') {
                    return newChips;
                }

                newChips.push({
                    label: 'Search Text',
                    field: 'searchText',
                    value: searchTextQuery,
                    displayValue: searchTextQuery
                });

                return newChips;
            });
        }, 300),
        []
    );

    const handleInputChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const searchTextQuery = event.target.value;
        handleSearchTextChange(searchTextQuery);
    }, []);

    const handleStatusChange = useCallback((value: string, checked: boolean): void => {
        setChips((prevChips) => {
            // Check if the chip for this status already exists
            const chipExists = prevChips.some((chip) => chip.field === 'status' && chip.value === value);
            const selectedOption = statusOptions.find((option) => option.value === value);

            if (selectedOption) {
                selectedOption.selected = checked;
                setStatusOptions([...statusOptions]);
            }

            // Create a new chips array based on whether the checkbox is checked or not
            const newChips = checked
                ? chipExists
                    ? prevChips // If chip exists and checked, no change needed
                    : [...prevChips, createChip('status', value, 'Status', selectedOption?.label)] // If not exists and checked, add chip
                : prevChips.filter((chip) => !(chip.field === 'status' && chip.value === value)); // If unchecked, remove chip

            return newChips;
        });
    }, [chips, statusOptions]);

    const handleOwnerChange = useCallback((value: string, checked: boolean): void => {
        setChips((prevChips) => {
            // Check if the chip for this owner already exists
            const chipExists = prevChips.some((chip) => chip.field === 'owner' && chip.value === value);
            const selectedOption = ownerOptions.find((option) => option.value === value);

            if (selectedOption) {
                selectedOption.selected = checked;
                setOwnerOptions([...ownerOptions]);
            }

            // Create a new chips array based on whether the checkbox is checked or not
            const newChips = checked
                ? chipExists
                    ? prevChips // If chip exists and checked, no change needed
                    : [...prevChips, createChip('owner', value, 'Owner', selectedOption?.label)] // If not exists and checked, add chip
                : prevChips.filter((chip) => !(chip.field === 'owner' && chip.value === value)); // If unchecked, remove chip

            return newChips;
        });
    }, [chips, ownerOptions]);

    const handleProjectChange = useCallback((value: string, checked: boolean): void => {
        setChips((prevChips) => {
            // Check if the chip for this project already exists
            const chipExists = prevChips.some((chip) => chip.field === 'project' && chip.value === value);
            const selectedOption = projectOptions.find((option) => option.value === value);

            if (selectedOption) {
                selectedOption.selected = checked;
                setProjectOptions([...projectOptions]);
            }

            // Create a new chips array based on whether the checkbox is checked or not
            const newChips = checked
                ? chipExists
                    ? prevChips // If chip exists and checked, no change needed
                    : [...prevChips, createChip('project', value, 'Project', selectedOption?.label)] // If not exists and checked, add chip
                : prevChips.filter((chip) => !(chip.field === 'project' && chip.value === value)); // If unchecked, remove chip

            return newChips;
        });
    }, [chips, projectOptions]);

    // #endregion


    useUpdateEffect(() => {
        handleChipsUpdate();
    }, [chips, handleChipsUpdate]);


    useEffect(() => {
        if (projectsStore.projects && projectsStore.projects.length > 0) {
            const newOptions: FilterOption[] = projectsStore.projects.map(project => ({
                label: project.name,
                value: project._id,
                selected: false
            } as FilterOption));
            setProjectOptions(newOptions);
        }

        setOwnerOptions([
            {
                label: 'Mine',
                value: 'mine',
                selected: false
            },
            {
                label: 'Smart Access',
                value: 'smartaccess',
                selected: false
            }
        ]);

        setStatusOptions([
            {
                label: 'Active',
                value: 'Active',
                selected: false
            },
            {
                label: 'Inactive',
                value: 'Inactive',
                selected: false
            }
        ]);

    }, [projectsStore.projects]);


    const showChips = chips.length > 0;

    return (
        <div>
            <Stack alignItems="center" direction="row" spacing={2} sx={{ py: 1 }}>
                <SvgIcon>
                    <SearchMdIcon />
                </SvgIcon>

                <Input disableUnderline fullWidth
                    inputProps={{ ref: searchTextRef }}
                    placeholder="Search name, description, and tags"
                    sx={{ flexGrow: 1, }}
                    type="search"
                    inputRef={input => input && input.focus()}
                    onChange={handleInputChange} />

                <Tooltip title="Filter list">
                    <Stack direction={'column'} alignItems="center">
                        <IconButton onClick={handleFiltersDialogOpen}>
                            <FilterListIcon />
                        </IconButton>
                        <Typography variant="caption">Filters</Typography>
                    </Stack>
                </Tooltip>
            </Stack>

            <Divider />

            {showChips &&
                <Stack alignItems="center" direction="row" flexWrap="wrap" gap={1} sx={{ p: 2 }}>
                    {chips.map((chip, index) => (
                        <Chip
                            key={index}
                            label={(
                                <Box sx={{ alignItems: 'center', display: 'flex', '& span': { fontWeight: 600 } }}>
                                    <>
                                        <span>{chip.label}</span>
                                        :
                                        {' '}
                                        {chip.displayValue || chip.value}
                                    </>
                                </Box>
                            )}
                            onDelete={(): void => handleChipDelete(chip)}
                            variant="outlined"
                        />
                    ))}
                </Stack>
            }
            <Divider />

            <AssetsFilterDialog
                open={open}
                handleClose={handleFiltersDialogClose}
                handleStatusChange={handleStatusChange}
                handleOwnerChange={handleOwnerChange}
                handleProjectChange={handleProjectChange}
                subjectOptions={subjectOptions || []}
                formatOptions={formatOptions || []}
                productOptions={productOptions || []}
                projectOptions={projectOptions || []}
                statusOptions={statusOptions || []}
                ownerOptions={ownerOptions || []}
            />

        </div>
    );
};


// TODO - untested, to be used with global search
// const [searchParams] = useSearchParams();
// useEffect(() => {

//     if (searchParams.get('status')) {
//         setChips([{
//             label: 'Status',
//             field: 'status',
//             value: searchParams.get('status'),
//             displayValue: searchParams.get('status')
//         }]);
//     }

//     if (searchParams.get('searchtext')) {
//         searchTextRef.current!.value = searchParams.get('searchtext') ?? '';
//         handleSearchTextChange(searchParams.get('searchtext'));
//         setChips([{
//             label: 'Search Term',
//             field: 'searchText',
//             value: searchParams.get('searchtext'),
//             displayValue: searchParams.get('searchtext')
//         }]);
//     }

//     if (searchParams.get('owner')) {
//         setChips([{
//             label: 'Owner',
//             field: 'owner',
//             value: searchParams.get('owner'),
//             displayValue: searchParams.get('owner')
//         }]);
//     }
// }, []);
