import { FC } from 'react';
import { useState } from 'react';
import { Button, Dialog, DialogContent, DialogProps, Divider, FormControlLabel, FormGroup, Grid, IconButton, Stack, SvgIcon, Switch, Typography } from '@mui/material';
import PlusIcon from '@untitled-ui/icons-react/build/esm/Plus';
import { DisplayAdditionalField, DisplayAdditionalFieldKey } from 'types/assets/asset';
import CloseIcon from '@mui/icons-material/Close';


interface IProps {
    displayFields: DisplayAdditionalField;
    updateFieldsToDisplay: (key: string, value: boolean) => void;
}

const AdditionalFieldsDialog: FC<IProps> = ({ displayFields, updateFieldsToDisplay }) => {
    const [open, setOpen] = useState(false);
    const [maxWidth] = useState<DialogProps['maxWidth']>('md');

    const handleDialogOpen = () => {
        setOpen(true);
    };

    const handleDialogClose = () => {
        setOpen(false);
    };

    const handleToggle = (key: DisplayAdditionalFieldKey) => {
        updateFieldsToDisplay(key, !displayFields[key]);
    }

    return (
        <>
            <Button startIcon={(<SvgIcon><PlusIcon /></SvgIcon>)} variant='outlined' color='info' onClick={handleDialogOpen} >
                Add Fields
            </Button>
            <Dialog open={open} onClose={handleDialogClose} maxWidth={maxWidth} scroll='paper' aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <IconButton aria-label="close" onClick={handleDialogClose} sx={{ position: 'absolute', right: 8, top: 8 }}>
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    <Stack spacing={1}>
                        <Typography variant='h6'>
                            Asset details
                        </Typography>
                        <Divider />
                        <Grid container >
                            <Grid item xs={12}>
                                <FormGroup sx={{ pl: 1 }}>
                                    <FormControlLabel name='categorisation' control={<Switch checked={displayFields['categorisation']} onChange={(e) => { handleToggle(e.target.name as DisplayAdditionalFieldKey) }} />} label="Categorisation  (Subject, Format, and Product)" />
                                </FormGroup>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormGroup sx={{ pl: 1 }}>
                                    <FormControlLabel name='tags' control={<Switch checked={displayFields['tags']} onChange={(e) => { handleToggle(e.target.name as DisplayAdditionalFieldKey) }} />} label="Tags" />
                                </FormGroup>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormGroup sx={{ pl: 1 }}>
                                    <FormControlLabel name='location' control={<Switch checked={displayFields['location']} onChange={(e) => { handleToggle(e.target.name as DisplayAdditionalFieldKey) }} />} label="Location" />
                                </FormGroup>
                            </Grid>
                        </Grid>
                        <Grid container sx={{ pt: 5 }}>
                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }} >
                                <Button onClick={handleDialogClose} variant='contained' color="primary">
                                    Select Fields
                                </Button>
                            </Grid>
                        </Grid>
                    </Stack>
                </DialogContent >
            </Dialog >
        </>
    );
};

export default AdditionalFieldsDialog;