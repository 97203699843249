import { Card, CardContent, Unstable_Grid2 as Grid, Stack, TextField, Typography } from '@mui/material';
import { useAuth } from 'hooks/use-auth';
import { FC, useEffect } from 'react';
import { useLogger } from 'hooks/use-logger';
import EnterprisePricingCard from './enterprise-pricing-card';

export const ManageBilling: FC = () => {

    const auth = useAuth();
    const [log] = useLogger();

    // commented out below for now, causes Firebase: Error (auth/quota-exceeded).
    // const handleVisibilityChange = function () {
    //     if (document.visibilityState === 'visible') {
    //         // cheap way of saying "they've come back to the tab, so reload the user", and their credits will be updated
    //         auth.reloadUser();
    //     }
    // }
    // document.addEventListener("visibilitychange", handleVisibilityChange);

    useEffect(() => {
        log(auth.authUser?.email ?? '', "Billing");
    }, []);

    return (
        <>
            <Stack spacing={4}>
                <Card>
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid xs={12} md={2}>
                                <Typography variant="h6">
                                    Billing
                                </Typography>
                            </Grid>

                            <Grid xs={12} md={6}>
                                <Stack spacing={3}>
                                    <Stack alignItems="center" direction="row" spacing={2}>
                                        <TextField disabled sx={{ flexGrow: 1 }} label="Subscription Plan" value={auth.getSubscriptionLevel()} />
                                    </Stack>
                                    <Stack alignItems="center" direction="row" spacing={2}>
                                        <TextField disabled sx={{ flexGrow: 1 }} label="Seat Count" value={auth.organisation?.seatCount ?? "0"} />
                                    </Stack>
                                    <Stack alignItems="center" direction="row" spacing={2}>
                                        <TextField disabled sx={{ flexGrow: 1 }} label="Monthly Credits" value={auth.organisation?.monthlyCredits ?? "0"} />
                                    </Stack>
                                </Stack>
                                {/* {JSON.stringify(auth.organisation, null, 2)} */}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>


                {auth.getSubscriptionLevel() !== 'ENTERPRISE' &&
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid xs={12} md={2}>
                                </Grid>
                                <Grid xs={12} md={6}>
                                    <Stack spacing={3}>
                                        <EnterprisePricingCard />
                                    </Stack>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                }
            </Stack>
        </>
    );
};


