import { Box, Button, Container, Divider, Stack, Tab, Tabs } from '@mui/material';
import { useCallback, useEffect, useState, ChangeEvent } from 'react';
import type { Page as PageType } from 'types/page';
import { Seo } from 'components/seo';
import PeerdwebService from 'services/peerdweb/peerdweb-service';
import { useAuth } from 'hooks/use-auth';
import { useRouter } from 'hooks/use-router';
import { useParams } from 'react-router-dom';
import { Asset, AssetStorage } from 'types/assets/asset';
import { paths } from 'paths';
import { ValidateAssetResponse } from 'types/validate/validate';
import { ViewAsset } from 'components/assets/view-asset/view-asset';
import { UpdateAsset } from 'components/assets/view-asset/update-asset';
import { SmartAccess } from 'components/assets/smart-access/smart-access';
import { useLogger } from 'hooks/use-logger';
import { ActivityLogsEnhancedTable } from 'components/assets/smart-access/activity-logs-enhanced-table';
import { useSmartAccessActivityLogStore } from 'hooks/smart-access/useSmartAccessActivityLogStore';

const tabs = [
    { label: "Asset", value: "view", ownerOnly: false },
    { label: "Update Asset", value: "update", ownerOnly: true },
    { label: "Smart Access Control", value: "access", ownerOnly: true },
    { label: "Activity Log", value: "log", ownerOnly: true },
];

const ViewAssetPage: PageType = () => {
    const auth = useAuth();
    const { peerdwebUser } = useAuth();
    const peerdwebService = new PeerdwebService();
    const { id } = useParams();
    const router = useRouter();
    const [asset, setAsset] = useState<Asset>();
    const [assetStorage, setAssetStorage] = useState<AssetStorage>();
    const [transaction, setTransaction] = useState<ValidateAssetResponse>();
    const [currentTab, setCurrentTab] = useState<string>("view");
    const [userHasAccess, setUserHasAccess] = useState<boolean>(false);
    const [userIsOwner, setUserIsOwner] = useState<boolean>(false);
    const [log] = useLogger();
    const activityLogStore = useSmartAccessActivityLogStore();

    if (id == null)
        throw new Error("Asset id is null");

    async function getAsset() {
        try {
            const assetResponse = await peerdwebService.getAssetById(id as string, peerdwebUser?._id as string)
            setAsset(assetResponse.data);

            if (assetResponse.data.ownerFirebaseUid === peerdwebUser?.firebaseUid)
                setUserIsOwner(true);

            setUserHasAccess(true);

            var transactionResponse;
            // we were minting the Zip file hash to the blockchain, as we had multi-files option 
            // - but now we're strict to a single file per assets, so we try file first then zip
            try {
                transactionResponse = await peerdwebService.validateAssetHash(assetResponse.data.files[0].hash);
            } catch (error) {
                // legacy mints
                console.log("Legacy mint");
                transactionResponse = await peerdwebService.validateAssetHash(assetResponse.data.zipHash);
            }

            if (transactionResponse)
                setTransaction(transactionResponse.data);

            const assetStorageResponse = await peerdwebService.getAssetStorage(id as string);
            if (assetStorageResponse)
                setAssetStorage(assetStorageResponse.data);

            await activityLogStore.searchSmartAccessActivityLog({ assetId: id });
        }
        catch (err: any) {
            if (err.response.status === 401) {
                setUserHasAccess(false);
                log(auth.authUser?.email ?? '', "View Asset // access denied", "Error", { assetId: id, authUser: auth.authUser });
                return;
            }
            if (err.response.status === 404) {
                setUserHasAccess(false);
                log(auth.authUser?.email ?? '', "View Asset // asset not found", "Error", { assetId: id, authUser: auth.authUser });
                return;
            }
            console.error(err);
        }
    }

    useEffect(() => {
        getAsset();
    }, []);

    const handleTabsChange = useCallback((event: ChangeEvent<any>, value: string): void => {
        setCurrentTab(value);
    }, []);

    return (
        <>
            <Seo title="Protected: Asset View" />
            <Box component="main" sx={{ flexGrow: 1, py: 2 }}>
                <Container maxWidth='xl'>

                    {!userHasAccess && <>
                        Access denied.<br />
                        You do not have access to view this asset. Please contact the asset owner.
                    </>
                    }

                    {userHasAccess &&
                        <>
                            <Stack spacing={3} sx={{ mb: 3 }}>
                                <div>
                                    <Tabs indicatorColor="primary" onChange={handleTabsChange} scrollButtons="auto" textColor="primary" value={currentTab} variant="scrollable">
                                        {tabs.map((tab) => (
                                            !tab.ownerOnly &&
                                            <Tab key={tab.value} label={tab.label} value={tab.value} />
                                        ))}
                                        {tabs.map((tab) => (
                                            tab.ownerOnly && userIsOwner &&
                                            <Tab key={tab.value} label={tab.label} value={tab.value} />
                                        ))}
                                    </Tabs>
                                    <Divider />
                                </div>
                            </Stack>


                            {currentTab === "view" &&
                                <ViewAsset asset={asset} transaction={transaction} assetStorage={assetStorage} />
                            }


                            {currentTab === "update" && asset &&
                                <UpdateAsset asset={asset} />
                            }

                            {currentTab === "access" &&
                                <SmartAccess asset={asset} />
                            }


                            {currentTab === "log" &&
                                <ActivityLogsEnhancedTable activityLogs={activityLogStore.state} csvFileName={asset?._id + "-activity-log.csv"} hideAssetColumn={true} />
                            }

                        </>
                    }

                    <Divider sx={{ mt: 8, mb: 4, borderColor: '#6C737F' }}></Divider>

                    <Box sx={{ p: 1 }}>
                        <Button variant="outlined" onClick={() => router.push(paths.assets.list)}>
                            Back to Assets
                        </Button>
                        {asset && asset?.project && asset.project._id &&
                            <Button variant="outlined" onClick={() => asset?.project?._id && router.push(paths.projects.view(asset.project._id as string))} sx={{ ml: 4 }}>
                                View Project
                            </Button>
                        }
                    </Box>

                </Container>
            </Box >
        </>
    );
};

export default ViewAssetPage;