import { Card, CardContent, Divider, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { Scrollbar } from 'components/scrollbar';
import { usePageView } from 'hooks/use-page-view';
import type { Page as PageType } from 'types/page';
import { useSuperUserOrganisationsStore } from 'hooks/super-admin/useSuperUserOrganisationsStore';

const SuperAdminOrganisations: PageType = () => {
    const allOrganisations = useSuperUserOrganisationsStore();

    usePageView();

    return (
        <Stack spacing={4}>
            <Stack direction="row" justifyContent="space-between" spacing={4}>
                <Stack spacing={1}>
                    <Typography variant="h4">
                        Organisations ({allOrganisations.total})
                    </Typography>
                </Stack>
            </Stack>

            <Card>
                <CardContent>
                    <Scrollbar sx={{ '& .simplebar-placeholder': { height: '1px !important' } }}>
                        <Table sx={{ minWidth: 400 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        Organisation
                                    </TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {allOrganisations.organisations?.map((organisation, index) => (
                                    <TableRow key={index}>
                                        <TableCell>
                                            <Stack alignItems="left" direction="column" spacing={1}>
                                                <Typography variant="body1">
                                                    {organisation.name}
                                                </Typography>
                                                <Divider sx={{ my: 1 }} />
                                                <Typography color="text.secondary" variant="body2">
                                                    Subscription Level: {organisation.subscriptionLevel}<br />
                                                    Seat Count: {organisation.seatCount}<br />
                                                </Typography>
                                                <Divider sx={{ my: 1 }} />
                                                <Typography color="text.secondary" variant="body2">
                                                    AWS Bucket: {organisation.aws?.bucket}<br />
                                                </Typography>
                                            </Stack>
                                        </TableCell>
                                        <TableCell>
                                            Users: {organisation.users?.length}<br />
                                            {organisation.users?.map((user, index) => (
                                                <span key={index}>
                                                    {user.displayName} <br />
                                                </span>
                                            ))}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Scrollbar>
                </CardContent>
            </Card>
        </Stack>
    );
};

export default SuperAdminOrganisations;
