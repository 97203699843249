import { FileConstraints } from 'core/files-zipper';
import * as Yup from 'yup';

//TODO: ensure we are checking this stuff server-side as well
//TODO: we can put type and size restrictions on the files here
export const createAssetValidationSchema = Yup.object({
    titleHeadline: Yup.string()
        .max(255, "Must be 255 characters or less")
        .required("Name is required"),
    abstract: Yup.string()
        .max(5000, "Must be 5000 characters or less"),
    files: Yup.array()
        .min(1, "You must select a file")
        .max(FileConstraints.maxFiles, `No more than ${FileConstraints.maxFiles} files are allowed`),
});