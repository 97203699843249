import type { FC } from 'react';
import { Box } from '@mui/material';
import { Logo } from './logo';
import { keyframes } from '@mui/system';


export const SplashScreen: FC = () => {
    const spin = keyframes`
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    `;


    return (
        <Box
            sx={{
                alignItems: 'center',
                backgroundColor: 'transparent',
                display: 'flex',
                flexDirection: 'column',
                height: '100vh',
                justifyContent: 'center',
                left: 0,
                p: 3,
                position: 'fixed',
                top: 0,
                width: '100vw',
                zIndex: 1400,
                animation: `${spin} 1s infinite ease`
            }}
        >
            <Box sx={{ display: 'inline-flex', height: 48, width: 48 }}>
                <Logo />
            </Box>
        </Box>
    )
};
