import { ChangeEvent, MouseEvent, useCallback, useEffect, useState } from 'react';
import { useMounted } from 'hooks/use-mounted';
import PeerdwebService from 'services/peerdweb/peerdweb-service';
import { useAuth } from 'hooks/use-auth';
import { CreateProjectRequest, Project, ProjectSearchRequest } from 'types/project/project';
import { AxiosError } from 'axios';

export const useProjectsSearch = (organisationId: string) => {
    const [state, setState] = useState<ProjectSearchRequest>({
        name: '',
        organisationId: organisationId,
        ownerId: '',
        page: 0,
        pageSize: 25,
    });

    const handleOrganisationChange = useCallback((id: string): void => {
        setState((prevState) => ({
            ...prevState,
            organisationId: id
        }));
    }, []);

    const handleSearchChange = useCallback((name: string): void => {
        setState((prevState) => ({
            ...prevState,
            name
        }));
    }, []);

    const handlePageChange = useCallback((event: MouseEvent<HTMLButtonElement> | null, page: number): void => {
        setState((prevState) => ({
            ...prevState,
            page
        }));
    }, []);

    const handleRowsPerPageChange = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState) => ({
            ...prevState,
            pageSize: parseInt(event.target.value, 10),
            page: 0
        }));
    }, []);

    return {
        handleSearchChange,
        handlePageChange,
        handleRowsPerPageChange,
        handleOrganisationChange,
        state
    };
};

interface ProjectsStoreState {
    projects: Project[];
    projectsCount: number;
    errorMessage: string | null;
}

export const useProjectsStore = (searchState: ProjectSearchRequest) => {
    const isMounted = useMounted();
    const auth = useAuth();
    const [state, setState] = useState<ProjectsStoreState>({
        projects: [],
        projectsCount: 0,
        errorMessage: null
    });

    const peerdwebService = new PeerdwebService();

    const handleSearchProjects = useCallback(async () => {
        try {
            const response = await peerdwebService.searchProjects({ ...searchState });

            if (isMounted()) {
                setState({
                    projects: response.data.projects,
                    projectsCount: response.data.total,
                    errorMessage: null
                });
            }
        } catch (err) {
            console.error(err);
        }
    },
        [searchState, isMounted]
    );

    const handleCreateProject = useCallback(async () => {
        try {
            const createProjectRequest = {
                name: searchState.name,
                ownerId: auth.peerdwebUser?._id ?? '',
                organisationId: auth.organisation?._id ?? ''
            } as CreateProjectRequest;

            try {
                await peerdwebService.createProject(createProjectRequest);
            } catch (error: any) {
                const axiosError = error as AxiosError;
                if (axiosError.response) {
                    if (axiosError.response.status === 500 && typeof axiosError.response.data === 'object' && axiosError.response.data !== null && 'error' in axiosError.response.data && axiosError.response.data.error === 'Project already exists') {
                        setState({
                            ...state,
                            errorMessage: `Project already exists: ${searchState.name}`
                        });
                        return;
                    }
                }
                setState({
                    ...state,
                    errorMessage: `An error occurred creating project: ${searchState.name}`
                });
            }

            if (isMounted()) {
                setState((prevState) => ({
                    ...prevState,
                    errorMessage: ''
                }));
                handleSearchProjects();
            }

        } catch (err) {
            console.error(err);
        }
    },
        [searchState, isMounted]
    );

    useEffect(() => {
        handleSearchProjects();
    }, [searchState]);

    return {
        ...state,
        setState,
        handleCreateProject,
        handleSearchProjects
    };
};