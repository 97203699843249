import { FC, useEffect } from 'react';
import { Link, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { Asset, AssetStorage } from 'types/assets/asset';
import { ValidateAssetResponse } from 'types/validate/validate';
import GppGoodIcon from '@mui/icons-material/GppGood';
import { toPrettyBlockchainName, toPrettyDateTime } from 'utils/pretty-pretty';
import { useAuth } from 'hooks/use-auth';
import { useLogger } from 'hooks/use-logger';
import ObligationDialog from './obligation-dialog';
import SplashDisplay from '../splash/splash-display';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

interface IProps {
    asset?: Asset;
    transaction?: ValidateAssetResponse;
    assetStorage?: AssetStorage;
}

export const ViewAsset: FC<IProps> = ({ asset, transaction, assetStorage }) => {
    const { authUser } = useAuth();
    const [log] = useLogger();

    useEffect(() => {
        log(authUser?.email ?? '', "View Asset // " + asset?.headline, "Information", { assetId: asset?._id });
    }, []);

    return (
        <>
            <Typography variant="h4" sx={{ mb: 2 }}>
                Asset
            </Typography>
            <Table sx={{ mb: 6 }}>
                <TableBody>
                    <TableRow>
                        <TableCell sx={{ maxWidth: 200, minWidth: 80, width: 200 }}>Title</TableCell>
                        <TableCell align="left"><strong>{asset?.headline}</strong></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Timestamp</TableCell>
                        <TableCell align="left">{toPrettyDateTime(new Date(transaction?.timestamp ?? ''))}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>File</TableCell>
                        <TableCell align="left">
                            {asset?.files.map((file, index) =>
                                <span key={index}>{file.filename}</span>
                            )}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Certificate</TableCell>
                        <TableCell align="left">
                            <Typography component={Link} variant="body2" sx={{ display: "inline-flex", alignItems: "center" }} href={`${process.env.REACT_APP_PEERDWEB_API_BASE_URL}/assets/${asset?._id}/certificate`} target='_blank'>
                                View Timestamp Certificate<GppGoodIcon />
                            </Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Blockchain</TableCell>
                        <TableCell align="left"><strong>{toPrettyBlockchainName(transaction?.blockchain ?? '')} {toPrettyBlockchainName(transaction?.network ?? '')}</strong></TableCell>
                    </TableRow>
                    {/* <TableRow>
                        <TableCell>Status</TableCell>
                        <TableCell align="left"><strong>{transaction?.status}</strong></TableCell>
                    </TableRow> */}
                    <TableRow>
                        <TableCell>Transaction</TableCell>
                        <TableCell align="left">
                            <Link href={transaction?.explorerUrl + 'tx/' + transaction?.txHash} target="_blank" rel="noopener noreferrer" sx={{ display: "inline-flex", alignItems: "center" }} >
                                View on Blockchain Explorer <OpenInNewIcon sx={{ ml: 1 }} />
                            </Link>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Transaction Hash</TableCell>
                        <TableCell align="left">
                            <SplashDisplay hash={transaction?.txHash ?? ''} />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Block Hash</TableCell>
                        <TableCell align="left">
                            <SplashDisplay hash={transaction?.blockHash ?? ''} />
                        </TableCell>
                    </TableRow>
                    {asset?.abstract != null && asset?.abstract != '' &&
                        <TableRow>
                            <TableCell sx={{ verticalAlign: 'text-top' }}>Description</TableCell>
                            <TableCell align="left" sx={{ verticalAlign: 'text-top' }}>
                                <div dangerouslySetInnerHTML={{ __html: asset?.abstract }} /></TableCell>
                        </TableRow>
                    }

                </TableBody>
            </Table>

            {assetStorage &&
                <ObligationDialog asset={asset} assetStorage={assetStorage} />
            }
        </>
    );
};

