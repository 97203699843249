import { Box, Card, Divider, FormHelperText, Grid, Stack, Typography } from '@mui/material';
import { Seo } from 'components/seo';
import { usePageView } from 'hooks/use-page-view';
import type { Page as PageType } from 'types/page';
import { useCallback, useEffect, useState } from 'react';
import type { File } from 'components/assets/new-asset/select-file-dropzone';
import Hasher from 'core/hasher';
import { FileConstraints } from 'core/files-zipper';
import { useFormik } from 'formik';
import ValidateFilesValues from './verify-files-values';
import { validateFilesValidationSchema } from 'validation/validate/validate-files-validation-schema';
import PeerdwebService from 'services/peerdweb/peerdweb-service';
import { Container } from '@mui/system';
import { ValidateFileDropzone } from 'components/validate/validate-file-dropzone';
import { useAuth } from 'hooks/use-auth';
import { useLogger } from 'hooks/use-logger';

interface ValidationResult {
    fileName: string;
    isValid: boolean;
    timeValidated: Date | null;
}

const validateFilesInitialValues: ValidateFilesValues = {
    files: [],
};

const VerifyPage: PageType = () => {
    usePageView();

    const [files, setFiles] = useState<File[]>(new Array<File>());
    const auth = useAuth();
    const [log] = useLogger();

    const peerdwebService = new PeerdwebService();

    const onSubmit = async (values: ValidateFilesValues) => {
        try {
            let results: ValidationResult[] = [];

            const promises = values.files.map(async (file): Promise<ValidationResult> => {
                const assetHash = await Hasher.generate(file);

                try {
                    const validationResponse = await peerdwebService.validateAssetHash(assetHash);

                    return {
                        fileName: file.name,
                        isValid: validationResponse.status === 200,
                        timeValidated: new Date(validationResponse.data.timestamp ?? 0),
                    };
                }
                catch (err: any) {
                    if (err.response.status === 404) {
                        return {
                            fileName: file.name,
                            isValid: false,
                            timeValidated: null,
                        };
                    }

                    throw err;
                }
            });

            results = await Promise.all(promises);
        }
        catch (err) {
            console.error(err);
        }
    };

    const formik = useFormik({
        initialValues: validateFilesInitialValues,
        validationSchema: validateFilesValidationSchema,
        onSubmit: onSubmit,
    });

    const handleFilesDrop = useCallback((newFiles: File[]): void => {
        setFiles((prevFiles) => {
            return [...prevFiles, ...newFiles];
        });
    }, []);

    const handleFileRemove = useCallback((file: File): void => {
        setFiles((prevFiles) => {
            return prevFiles.filter((_file) => _file.path !== file.path);
        });
    }, []);

    const handleFilesRemoveAll = useCallback((): void => {
        setFiles([]);
    }, []);

    useEffect(() => {
        formik?.setFieldValue('files', files);
    }, [files]);

    useEffect(() => {
        log(auth.authUser?.email ?? '', "Verify");
    }, []);

    return (
        <>
            <Seo title="Peerdweb: Verify" />
            <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
                <Container maxWidth="xl">
                    <Stack spacing={3} sx={{ mb: 3 }}>
                        <Typography variant="h4">
                            Verify
                        </Typography>
                        <Divider />
                        <Card style={{ padding: 20 }}>
                            <form onSubmit={formik.handleSubmit}>
                                <Typography variant="body1" sx={{ my: 2 }}>
                                    Select the asset file you want to verify below.  This will be in the form of a .{process.env.REACT_APP_PROTECTED_FILE_EXT} file or the original unmodified file.
                                </Typography>

                                <Grid item xs={12} >
                                    <ValidateFileDropzone
                                        caption={`Maximum file size ${FileConstraints.maxSizeMb} MB`}
                                        onDrop={handleFilesDrop}
                                        onRemove={handleFileRemove}
                                        onRemoveAll={handleFilesRemoveAll}
                                        files={files}
                                        maxSize={FileConstraints.maxSizeMb * 1024 * 1024}
                                        maxFiles={FileConstraints.maxFiles}
                                    />
                                    <FormHelperText error>
                                        <>
                                            {formik.touched.files && formik.errors.files}
                                        </>
                                    </FormHelperText>
                                </Grid>
                            </form>
                        </Card>
                    </Stack>
                </Container>
            </Box>
        </>
    );
};
export default VerifyPage;