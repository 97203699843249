import { FC } from 'react';
import { Button, InputAdornment, Stack, SvgIcon, TextField, Typography } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

interface IProps {
    removeProject: () => void;
    name?: string;
}

export const ProjectField: FC<IProps> = ({ name, removeProject }) => {

    const handleRemoveProject = () => {
        removeProject();
    };

    return (
        <>
            <Stack direction="row" alignItems={'center'} spacing={1} sx={{ pt: 5 }}>
                <Typography variant="h6" sx={{ mb: 0, minWidth: 100 }}>
                    Project
                </Typography>
                <Stack direction="row" spacing={3} alignItems="center">
                    <TextField
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Button startIcon={(<SvgIcon><ClearIcon /></SvgIcon>)} variant='text' color='info' onClick={handleRemoveProject} />
                                </InputAdornment>
                            ),
                        }}
                        label={name}
                        name="project"
                        type="text"
                        disabled
                    />
                </Stack>
            </Stack>
        </>
    );
};