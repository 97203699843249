import type { SVGProps } from 'react';

const CheckDone01 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M2 11.2c0-1.1201 0-1.6802.218-2.108a1.9999 1.9999 0 0 1 .874-.874C3.5198 8 4.08 8 5.2 8h7.6c1.1201 0 1.6802 0 2.108.218.3763.1917.6823.4977.874.874.218.4278.218.988.218 2.108v7.6c0 1.1201 0 1.6802-.218 2.108a1.9996 1.9996 0 0 1-.874.874C14.4802 22 13.9201 22 12.8 22H5.2c-1.1201 0-1.6802 0-2.108-.218a1.9997 1.9997 0 0 1-.874-.874C2 20.4802 2 19.9201 2 18.8v-7.6Z"
      opacity={0.12}
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m6 15 2 2 4.5-4.5M8 8V5.2c0-1.1201 0-1.6802.218-2.108a1.9999 1.9999 0 0 1 .874-.874C9.5198 2 10.08 2 11.2 2h7.6c1.1201 0 1.6802 0 2.108.218.3763.1917.6823.4977.874.874C22 3.5198 22 4.08 22 5.2v7.6c0 1.1201 0 1.6802-.218 2.108a1.9996 1.9996 0 0 1-.874.874C20.4802 16 19.9201 16 18.8 16H16M5.2 22h7.6c1.1201 0 1.6802 0 2.108-.218a1.9996 1.9996 0 0 0 .874-.874C16 20.4802 16 19.9201 16 18.8v-7.6c0-1.1201 0-1.6802-.218-2.108a1.9997 1.9997 0 0 0-.874-.874C14.4802 8 13.9201 8 12.8 8H5.2c-1.1201 0-1.6802 0-2.108.218a1.9999 1.9999 0 0 0-.874.874C2 9.5198 2 10.08 2 11.2v7.6c0 1.1201 0 1.6802.218 2.108.1917.3763.4977.6823.874.874C3.5198 22 4.08 22 5.2 22Z"
    />
  </svg>
);

export default CheckDone01;
