import { FC, useCallback, useRef, useState } from 'react';
import { Box, Button, Card, Chip, Divider, Input, Stack, SvgIcon, Typography } from '@mui/material';
import PlusIcon from '@untitled-ui/icons-react/build/esm/Plus';

interface IProps {
    onTagsChange: (tags: string[]) => void;
    tags?: string[];
}

export const TagsFields: FC<IProps> = ({ onTagsChange, tags }) => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [currentTags, setCurrentTags] = useState<string[]>(tags ?? []);

    const handleChipDelete = useCallback((deletedChip: string): void => {
        setCurrentTags((prevChips) => {
            return prevChips.filter((chip) => {
                return !(deletedChip === chip);
            });
        });
    }, []);

    const handleAddTag = useCallback((): void => {
        const inputValue = inputRef.current?.value;

        if (inputValue && !currentTags.some(tag => tag === inputValue)) {
            setCurrentTags((prevChips) => {
                const newChips = [...prevChips];
                const inputValue = inputRef.current?.value;
                if (inputValue === '') {
                    return newChips;
                }
                newChips.push(inputValue ?? '');
                onTagsChange(newChips);
                return newChips;
            });
            // Clear the input field after adding the tag
            inputRef.current!.value = '';
        }
    }, [currentTags]);

    return (
        <Stack spacing={1} sx={{ pt: 5 }}>
            <Typography variant="h6" sx={{ mb: 0 }}>
                Tags
            </Typography>

            <Card>
                <Stack alignItems="center" direction="row" spacing={2} sx={{ p: 2 }}>
                    <SvgIcon>
                        <PlusIcon />
                    </SvgIcon>
                    <Input
                        disableUnderline
                        fullWidth
                        inputProps={{ ref: inputRef }}
                        placeholder="Type tag to add..."
                        sx={{ flexGrow: 1, }}
                        type="text"
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                                handleAddTag();
                                event.preventDefault(); // Prevents the default action of submitting the form
                            }
                        }}
                    />
                    {/* <Input value="Add" onClick={handleAddTag} /> */}
                    <Button startIcon={(<SvgIcon><PlusIcon /></SvgIcon>)} variant='text' color='info' onClick={handleAddTag} sx={{ width: 170 }}>
                        Add new tag
                    </Button>
                </Stack>
                <Divider />
                <Stack alignItems="center" direction="row" flexWrap="wrap" gap={1} sx={{ p: 2 }}>
                    {currentTags.map((chip, index) => (
                        <Chip
                            key={index}
                            label={(
                                <Box sx={{ alignItems: 'center', display: 'flex', '& span': { fontWeight: 600 } }}>
                                    <>
                                        {chip}
                                    </>
                                </Box>
                            )}
                            onDelete={(): void => handleChipDelete(chip)}
                            variant="outlined"
                        />
                    ))}
                </Stack>
                <Divider />
            </Card>
        </Stack>
    );
};