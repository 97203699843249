import type { FC } from 'react';
import { useEffect, useState } from "react";
import PeerdwebService from "services/peerdweb/peerdweb-service";
import { DateRangeSearchRequest } from "types/analytics/date-range-search-request";
import { addDays, formatISO } from "date-fns";
import { Chart } from "components/analytics/chart";
import useChartOptions from 'hooks/use-chart-options';
import { Card, CardContent, CardHeader } from '@mui/material';

export const DownloadedUsersChart: FC = () => {
    const chartOptions = useChartOptions();

    const peerdwebService = new PeerdwebService();
    const [topDownloadedUsersSeries, setTopDownloadedUsersSeries] = useState<ApexAxisChartSeries>();

    const today = new Date();
    const [dateRange, setDateRange] = useState<DateRangeSearchRequest>({
        startDate: new Date(formatISO(addDays(today, -365), { representation: 'date' })),
        endDate: new Date(formatISO(today, { representation: 'date' })),
    });

    const getTopDownloadedUsers = async () => {
        const results = await peerdwebService.getTopDownloadedUsers(dateRange);

        const series: ApexAxisChartSeries = [
            {
                name: "Downloads",
                data: results.data.map((userDownloads): { x: any, y: any } => {
                    return {
                        x: userDownloads.user,
                        y: userDownloads.downloads,
                    }
                }),
            }
        ]
        setTopDownloadedUsersSeries(series);
    };

    useEffect(() => {
        getTopDownloadedUsers();
    }, [dateRange]);

    return (
        <Card>
            <CardHeader
                sx={{ pb: 0 }}
                title="Top Downloaded Users"
            />
            <CardContent sx={{ pt: 0 }}>
                {topDownloadedUsersSeries &&
                    <Chart
                        width={"100%"}
                        height={300}
                        options={chartOptions}
                        series={topDownloadedUsersSeries}
                        type="bar"
                    />}
            </CardContent>
        </Card>
    );
};
