import { useEffect, type FC } from 'react';
import ArrowRightIcon from '@untitled-ui/icons-react/build/esm/ArrowRight';
import { Box, Button, Card, CardActions, Divider, Stack, SvgIcon, Typography } from '@mui/material';
import { RouterLink } from 'components/router-link';
import { paths } from 'paths';
import FolderIcon from '@mui/icons-material/Folder';
import { useProjectsSearch, useProjectsStore } from 'hooks/projects/useProjectsStore';
import { useAuth } from 'hooks/use-auth';
import { PeerdwebColours } from 'theme/peerdweb-colours';

export const ProjectsSummary: FC = () => {
    const { organisation } = useAuth();
    const projectsSearch = useProjectsSearch(organisation?._id ?? '');
    const projectsStore = useProjectsStore(projectsSearch.state);

    useEffect(() => {
        projectsSearch.handleOrganisationChange(organisation?._id ?? '');
    }, [organisation]);

    return (
        <Card>
            <Stack alignItems="center" direction='row' spacing={3} sx={{ px: 4, py: 3 }}>
                <div>
                    <FolderIcon sx={{ display: "inline-flex", color: PeerdwebColours.Navy1, alignItems: "center", width: 48, height: 48 }} />
                </div>
                <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
                    <Typography color="text.secondary" variant="body2">
                        Projects
                    </Typography>
                    <Typography color="text.primary" variant="h4">
                        {projectsStore.projectsCount ?? 0}
                    </Typography>
                </Box>
            </Stack>
            <Divider />
            <CardActions>
                <Button color="inherit" endIcon={(<SvgIcon><ArrowRightIcon /></SvgIcon>)} size="small"
                    component={RouterLink}
                    href={paths.projects.list}
                >
                    View Projects
                </Button>
            </CardActions>
        </Card>
    );
};
