import { FC } from 'react';
import User01Icon from '@untitled-ui/icons-react/build/esm/User01';
import { Avatar, Card, CardContent, Stack, SvgIcon, Typography, Unstable_Grid2 as Grid, Table, TableHead, TableRow, TableCell, TableBody, Link } from '@mui/material';
import { useAuth } from 'hooks/use-auth';
import { Scrollbar } from 'components/scrollbar';

export const OrganisationOwner: FC = () => {
    const { organisation } = useAuth();

    return (

        <Stack spacing={4}>
            <Card>
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid xs={12} md={2}>
                            <Typography variant="h6">
                                Owner
                            </Typography>
                        </Grid>
                        <Grid xs={12} md={10}>
                            <Stack spacing={3}>
                                <Scrollbar sx={{ '& .simplebar-placeholder': { height: '1px !important' } }}>
                                    <Table sx={{ minWidth: 500 }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    Owner
                                                </TableCell>
                                                <TableCell>
                                                    Last Logged In
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>
                                                    <Stack alignItems="center" direction="row" spacing={1}>
                                                        <Avatar src={organisation?.owner.imageUrl} sx={{ height: 40, width: 40 }}>
                                                            <SvgIcon>
                                                                <User01Icon />
                                                            </SvgIcon>
                                                        </Avatar>
                                                        <div>
                                                            <Typography variant="subtitle2">
                                                                {organisation?.owner.displayName}
                                                            </Typography>
                                                            <Typography color="text.secondary" variant="body2">
                                                                <Link href={`mailto:${organisation?.owner.email}`}>{organisation?.owner.email}</Link>
                                                            </Typography>
                                                        </div>
                                                    </Stack>
                                                </TableCell>
                                                <TableCell>
                                                    {organisation?.owner.lastLoggedInAt && new Date(organisation?.owner.lastLoggedInAt).toLocaleDateString('en-GB', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
                                                </TableCell>
                                            </TableRow>

                                        </TableBody>
                                    </Table>
                                </Scrollbar>
                            </Stack>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Stack>
    );
};

