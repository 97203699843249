import { useMounted } from 'hooks/use-mounted';
import { useCallback, useEffect, useState } from 'react';
import PeerdwebService from 'services/peerdweb/peerdweb-service';
import { UserSearchResponse } from 'types/search/user-search';

export const useSuperUserUsersStore = () => {
    const isMounted = useMounted();
    const [allUsers, setAllUsers] = useState<UserSearchResponse>({
        users: [],
        total: 0
    });
    const peerdwebService = new PeerdwebService();

    const getAllUsers = useCallback(async () => {
        try {
            const response = await peerdwebService.superAdminGetUsers();
            if (isMounted()) {
                // setAllUsers({ users: response.data.users, total: response.data.total });
                const usersWithAssets = await Promise.all(response.data.users.map(async (user) => {
                    // Fetch assets for each user
                    const assetsResponse = await peerdwebService.superAdminGetUserAssets(user._id ?? '');
                    return {
                        ...user,
                        assets: assetsResponse.data.assets,
                    };
                }));
                setAllUsers({ users: usersWithAssets, total: response.data.total });
            }
        } catch (err) {
            console.error(err);
        }
    }, [isMounted]);

    useEffect(() => {
        getAllUsers();
    }, []);

    return {
        ...allUsers
    };
};