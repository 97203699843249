import type { FC } from 'react';
import { useEffect, useState } from "react";
import PeerdwebService from "services/peerdweb/peerdweb-service";
import { DateRangeSearchRequest } from "types/analytics/date-range-search-request";
import { addDays, formatISO } from "date-fns";
import { Chart } from "components/analytics/chart";
import { Card, CardContent, CardHeader } from '@mui/material';
import useChartOptions from 'hooks/use-chart-options';

export const DownloadedAssetsChart: FC = () => {
    const chartOptions = useChartOptions();

    const peerdwebService = new PeerdwebService();
    const [topDownloadedAssetsSeries, setTopDownloadedAssetsSeries] = useState<ApexAxisChartSeries>();

    const today = new Date();
    const [dateRange, setDateRange] = useState<DateRangeSearchRequest>({
        startDate: new Date(formatISO(addDays(today, -365), { representation: 'date' })),
        endDate: new Date(formatISO(today, { representation: 'date' })),
    });

    const getTopDownloadedAssets = async () => {
        const results = await peerdwebService.getTopDownloadedAssets(dateRange);

        const series: ApexAxisChartSeries = [
            {
                name: "Downloads",
                data: results.data.map((assetDownloads): { x: any, y: any } => {
                    return {
                        x: assetDownloads.asset,
                        y: assetDownloads.downloads,
                    }
                }),
            }
        ]
        setTopDownloadedAssetsSeries(series);
    };

    useEffect(() => {
        getTopDownloadedAssets();
    }, [dateRange]);

    return (
        <Card>
            <CardHeader
                sx={{ pb: 0 }}
                title="Assets Downloaded"
            />
            <CardContent sx={{ pt: 0 }}>
                {topDownloadedAssetsSeries &&
                    <Chart
                        width={"100%"}
                        height={300}
                        options={chartOptions}
                        series={topDownloadedAssetsSeries}
                        type="bar"
                    />
                }
            </CardContent>
        </Card>
    );
};
