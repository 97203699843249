import * as Yup from 'yup';

export const UpdateSmartNdaValidationSchema = Yup.object({
    obligationHtml: Yup.string()
        .required("Obligation text is required")
        .notOneOf(['<p><br></p>'], "Obligation text is required")
});

export const UpdateOrganisationValidationSchema = Yup.object({
    name: Yup.string()
        .max(100, "Must be 100 characters or less")
        .required("Name is required"),
});