import { FC } from 'react';
import { Autocomplete, Button, Checkbox, Dialog, DialogContent, Divider, FormControlLabel, FormGroup, Grid, IconButton, Stack, TextField, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';

interface Props {
    open: boolean;
    handleClose: () => void;
    handleStatusChange: (value: string, checked: boolean) => void;
    handleOwnerChange: (value: string, checked: boolean) => void;
    handleProjectChange: (value: string, checked: boolean) => void;
    subjectOptions: any[];
    formatOptions: any[];
    productOptions: any[];
    projectOptions: any[];
    statusOptions: any[];
    ownerOptions: any[];
}

const AssetsFilterDialog: FC<Props> = ({ open, handleClose, handleStatusChange, handleOwnerChange, handleProjectChange, subjectOptions, formatOptions, productOptions, projectOptions, statusOptions, ownerOptions }) => {
    return (
        <Dialog open={open} onClose={handleClose} maxWidth={'md'} scroll='paper' aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <IconButton aria-label="close" onClick={handleClose} sx={{ position: 'absolute', right: 8, top: 8 }}>
                <CloseIcon />
            </IconButton>
            <DialogContent>
                <Stack spacing={1}>
                    <Grid container sx={{ pt: 5 }}>
                        <Grid item xs={6}>
                            <Grid container sx={{ display: 'flex', justifyContent: 'left', alignContent: 'flex-start' }} >

                                <Grid item xs={12}>
                                    <Typography variant='h6' sx={{ pb: 2 }}>
                                        Filter by Categorisation
                                    </Typography>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12} sx={{ mt: 1 }}>
                                    <Autocomplete
                                        id="grouped-demo"
                                        options={subjectOptions ? subjectOptions.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter)) : []}
                                        groupBy={(option) => option.firstLetter}
                                        getOptionLabel={(option) => option.title}
                                        sx={{ width: 300, mb: 1 }}
                                        renderInput={(params) => <TextField {...params} label="Subject" />}
                                    />
                                    <Autocomplete
                                        id="grouped-demo"
                                        options={formatOptions ? formatOptions.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter)) : []}
                                        groupBy={(option) => option.firstLetter}
                                        getOptionLabel={(option) => option.title}
                                        sx={{ width: 300, mb: 1 }}
                                        renderInput={(params) => <TextField {...params} label="Format" />}
                                    />
                                    <Autocomplete
                                        id="grouped-demo"
                                        options={productOptions ? productOptions.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter)) : []}
                                        groupBy={(option) => option.firstLetter}
                                        getOptionLabel={(option) => option.title}
                                        sx={{ width: 300, mb: 1 }}
                                        renderInput={(params) => <TextField {...params} label="Product" />}
                                    />
                                </Grid>


                                <Grid item xs={12}>
                                    <Typography variant='h6' sx={{ mt: 5, pb: 2 }}>
                                        Filter by Owner
                                    </Typography>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12}>
                                    {ownerOptions.map((owner, index) => {
                                        const labelId = `owner-checkbox-${index}`;
                                        return (
                                            <FormGroup sx={{ pl: 1 }} key={index}>
                                                <FormControlLabel
                                                    key={index}
                                                    name='owner'
                                                    control={
                                                        <Checkbox
                                                            key={labelId}
                                                            color="primary"
                                                            checked={owner.selected}
                                                            inputProps={{
                                                                'aria-labelledby': labelId,
                                                            }}
                                                            onChange={(e) => handleOwnerChange(owner.value, e.target.checked)}
                                                        />
                                                    }
                                                    label={owner.label}
                                                />
                                            </FormGroup>
                                        );
                                    })}
                                </Grid>


                                <Grid item xs={12}>
                                    <Typography variant='h6' sx={{ mt: 5, pb: 2 }}>
                                        Filter by Status
                                    </Typography>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12}>
                                    {statusOptions.map((status, index) => {
                                        const labelId = `status-checkbox-${index}`;
                                        return (
                                            <FormGroup sx={{ pl: 1 }} key={index}>
                                                <FormControlLabel
                                                    key={index}
                                                    name='status'
                                                    control={
                                                        <Checkbox
                                                            key={labelId}
                                                            color="primary"
                                                            inputProps={{
                                                                'aria-labelledby': labelId,
                                                            }}
                                                            checked={status.selected}
                                                            // value={status.value}
                                                            onChange={(e) => handleStatusChange(status.value, e.target.checked)}
                                                        />
                                                    }
                                                    label={status.label}
                                                />
                                            </FormGroup>
                                        );
                                    })}
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={6}>
                            <Grid container sx={{ display: 'flex', justifyContent: 'left', alignContent: 'flex-start' }} >
                                <Grid item xs={12}>
                                    <Typography variant='h6' sx={{ pb: 2 }}>
                                        Filter by Project
                                    </Typography>
                                    <Divider />
                                </Grid>
                                {projectOptions.map((project, index) => {
                                    const labelId = `project-checkbox-${index}`;
                                    return (
                                        // <Grid item xs={6} key={index}> I liked 2 columns but...
                                        <Grid item xs={12} key={index}>
                                            <FormGroup sx={{ pl: 1 }} key={index}>
                                                <FormControlLabel
                                                    key={index}
                                                    name='categorisation'
                                                    control={
                                                        <Checkbox
                                                            key={labelId}
                                                            color="primary"
                                                            inputProps={{
                                                                'aria-labelledby': labelId,
                                                            }}
                                                            value={project.value}
                                                            checked={project.selected}
                                                            onChange={(e) => handleProjectChange(project.value, e.target.checked)}
                                                        />
                                                    }
                                                    label={project.label} />
                                            </FormGroup>
                                            <Typography variant="subtitle2" sx={{ cursor: 'pointer' }} onClick={() => { }}>
                                            </Typography>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container sx={{ pt: 5 }}>
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }} >
                            <Button startIcon={(<ArrowBackIcon />)} variant="contained" color='error' style={{ maxWidth: 300, position: 'absolute', left: '30px' }} onClick={handleClose} >
                                Go Back
                            </Button>
                            <Button onClick={handleClose} variant='contained' color="primary">
                                Apply Filters
                            </Button>
                        </Grid>
                    </Grid>
                </Stack>
            </DialogContent >
        </Dialog >
    );
};

export default AssetsFilterDialog;
