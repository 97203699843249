import { useState } from 'react';
import PeerdwebService from 'services/peerdweb/peerdweb-service';
import { useAuth } from 'hooks/use-auth';
import { useLogger } from 'hooks/use-logger';
import { SmartAccess } from 'types/smart-access/smart-access';

const useSmartAccessStore = () => {
    const { peerdwebUser } = useAuth();
    const [smartAccessList, setSmartAccessList] = useState<SmartAccess[]>([]);
    const [isReady, setIsReady] = useState<boolean>(false);
    const peerdwebService = new PeerdwebService();
    const [incomingCount, setIncomingCount] = useState<number>(0);
    const [outgoingCount, setOutgoingCount] = useState<number>(0);
    const [inviteCount, setInviteCount] = useState<number>(0);

    const getIncomingSmartAccessList = async () => {
        if (!peerdwebUser || peerdwebUser._id == undefined)
            return;

        try {
            const response = await peerdwebService.getIncomingSmartAccessList(peerdwebUser._id);
            const smartAccessData = response.data.smartAccesses || [];
            setSmartAccessList(smartAccessData);
            setIncomingCount(smartAccessData.length);
            setInviteCount(smartAccessData.filter((smartAccess: SmartAccess) => smartAccess.status === 'invite').length);
            setIsReady(true);
        } catch (error) {
            console.error(error);
        }
    };

    const getOutgoingSmartAccessList = async () => {
        if (!peerdwebUser || peerdwebUser._id == undefined)
            return;

        try {
            const response = await peerdwebService.getOutgoingSmartAccessList(peerdwebUser._id);
            const smartAccessData = response.data.smartAccesses || [];
            setSmartAccessList(smartAccessData);
            setOutgoingCount(smartAccessData.length);
            setIsReady(true);
        } catch (error) {
            console.error(error);
        }
    };

    const accept = async (smartAccessId?: string) => {
        if (!smartAccessId || !peerdwebUser || peerdwebUser._id === undefined)
            return;

        try {
            await peerdwebService.acceptSmartAccess(smartAccessId, peerdwebUser._id);
            getIncomingSmartAccessList();
        } catch (error) {
            console.error(error);
        }
    };

    const decline = async (smartAccessId?: string) => {
        if (!smartAccessId || !peerdwebUser || peerdwebUser._id === undefined)
            return;

        try {
            await peerdwebService.declineSmartAccess(smartAccessId, peerdwebUser._id);
            getIncomingSmartAccessList();
        } catch (error) {
            console.error(error);
        }
    };

    const relinquish = async (smartAccessId?: string) => {
        if (!smartAccessId || !peerdwebUser || peerdwebUser._id === undefined)
            return;

        try {
            await peerdwebService.relinquishSmartAccess(smartAccessId, peerdwebUser._id);
            getIncomingSmartAccessList();
        } catch (error) {
            console.error(error);
        }
    };

    const revoke = async (smartAccessId?: string) => {
        if (!smartAccessId || !peerdwebUser || peerdwebUser._id === undefined)
            return;

        try {
            await peerdwebService.revokeSmartAccess(smartAccessId, peerdwebUser._id);
            getOutgoingSmartAccessList();
        } catch (error) {
            console.error(error);
        }
    };

    return {
        smartAccessList,
        isReady,
        incomingCount,
        outgoingCount,
        inviteCount,
        accept,
        relinquish,
        decline,
        revoke,
        getIncomingSmartAccessList,
        getOutgoingSmartAccessList
    };
};

export default useSmartAccessStore;
