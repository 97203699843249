import { ChangeEvent, FC } from 'react';
import { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Input, Stack, SvgIcon } from '@mui/material';
import { debounce } from 'lodash';
import SearchMdIcon from '@untitled-ui/icons-react/build/esm/SearchMd';


interface IProps {
    onChange?: (name: string) => void;
}

export const ViewProjectsSearch: FC<IProps> = ({ onChange }) => {
    const headlineRef = useRef<HTMLInputElement | null>(null);
    const [projectName, setProjectName] = useState<string>('');
    const searchTextRef = useRef<HTMLInputElement | null>(null);

    const handleTextChange = useCallback(debounce((event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        setProjectName(event.target.value);
        onChange?.(event.target.value);
    }, 500), []);

    return (
        <Stack alignItems="center" direction="row" spacing={2} sx={{ py: 2 }}>
            <SvgIcon>
                <SearchMdIcon />
            </SvgIcon>
            <Input disableUnderline fullWidth
                inputProps={{ ref: searchTextRef }}
                placeholder="Search project name"
                sx={{ flexGrow: 1, }}
                type="search"
                inputRef={input => input && input.focus()}
                onChange={handleTextChange} />
        </Stack>

    );
};

ViewProjectsSearch.propTypes = {
    onChange: PropTypes.func
};
