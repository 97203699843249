import type { ChangeEvent } from "react";
import { Box, Container, Divider, Stack, Tab, Tabs, Typography } from '@mui/material';
import { Seo } from 'components/seo';
import { useAuth } from 'hooks/use-auth';
import { usePageView } from 'hooks/use-page-view';
import { useCallback, useEffect, useState } from 'react';
import type { Page as PageType } from 'types/page';
import SuperAdminUsers from "../../components/super-admin/users";
import SuperAdminAssets from "../../components/super-admin/assets";
import { useLogger } from "hooks/use-logger";
import { SuperAdminSmartAccesses } from "components/super-admin/smart-accesses";
import SuperAdminOrganisations from "components/super-admin/organisations";

const SuperAdminPage: PageType = () => {
    const [currentTab, setCurrentTab] = useState<string>("users");
    const { peerdwebUser } = useAuth();
    const auth = useAuth();
    const [log] = useLogger();

    usePageView();

    const handleTabsChange = useCallback((event: ChangeEvent<any>, value: string): void => {
        setCurrentTab(value);
    }, []);

    useEffect(() => {
        log(auth.authUser?.email ?? '', "Super Admin View");
    }, []);

    return (
        peerdwebUser?.superAdmin &&
        <>
            <Seo title="Peerdweb: Super Admin" />
            <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
                <Container maxWidth="xl">
                    <Stack spacing={3} sx={{ mb: 3 }}>
                        <Typography variant="h4">Owners Club</Typography>
                        <Divider />
                        <div>
                            <Tabs indicatorColor="primary" onChange={handleTabsChange} scrollButtons="auto" textColor="primary" value={currentTab} variant="scrollable">
                                <Tab label='Users' key='users' value='users' />
                                <Tab label='Organisations' key='organisations' value='organisations' />
                                <Tab label='Assets' key='assets' value='assets' />
                                <Tab label='Smart Accesses' key='smart-accesses' value='smart-accesses' />
                            </Tabs>
                            <Divider />
                        </div>
                    </Stack>

                    {currentTab === "users" &&
                        <SuperAdminUsers />
                    }

                    {currentTab === "assets" &&
                        <SuperAdminAssets />
                    }

                    {currentTab === "smart-accesses" &&
                        <SuperAdminSmartAccesses />
                    }

                    {currentTab === "organisations" &&
                        <SuperAdminOrganisations />
                    }

                </Container>
            </Box>
        </>
    );
};

export default SuperAdminPage;
