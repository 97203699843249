import type { ChangeEvent, FC, MouseEvent } from 'react';
import { Fragment } from 'react';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import { Project } from 'types/project/project';
import { useRouter } from 'hooks/use-router';
import { paths } from 'paths';

interface IProps {
    count?: number;
    items?: Project[];
    onPageChange?: (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
    onRowsPerPageChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    page?: number;
    pageSize?: number;
}

export const ViewProjectsTable: FC<IProps> = (props) => {
    const {
        count = 0,
        items = [],
        onPageChange = () => { },
        onRowsPerPageChange,
        page = 0,
        pageSize = 0,
    } = props;
    const router = useRouter();

    return (
        <Box>
            <TableContainer component={Paper} sx={{ width: "100%", height: 400, overflowX: 'auto' }}>
                <Table stickyHeader sx={{ minWidth: 800 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Assets Count</TableCell>
                            <TableCell>Owner</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items.map((project, index) => {
                            return (
                                <Fragment key={project._id}>
                                    <TableRow
                                        hover
                                        key={project._id}
                                        onClick={() => router.push(paths.projects.view(project._id as string))}
                                        sx={{ cursor: 'pointer' }}
                                    >
                                        <TableCell>
                                            <Typography variant="subtitle2" sx={{ cursor: 'pointer' }} onClick={() => { }}>
                                                {project.name}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="subtitle2">
                                                {project.assets.length}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="subtitle2">
                                                {project.owner.displayName}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </Fragment>
                            );
                        })}

                        {items.length == 0 &&
                            <TableRow>
                                <TableCell align='left' colSpan={2} sx={{ opacity: 0.5 }}>
                                    No projects found
                                </TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                component="div"
                count={count}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}
                page={page}
                rowsPerPage={pageSize}
                rowsPerPageOptions={[10, 25, 100]}
                labelRowsPerPage="Projects per page"
            />
        </Box>
    );
};
