import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Divider, Drawer, Stack } from '@mui/material';
import { Scrollbar } from 'components/scrollbar';
import { usePathname } from 'hooks/use-pathname';
import type { Section } from '../config';
import { MobileNavSection } from './mobile-nav-section';
import { OrganisationSwitch } from 'components/organisation-switch';

const MOBILE_NAV_WIDTH: number = 280;

interface IProps {
    onClose?: () => void;
    open?: boolean;
    sections?: Section[];
}

export const MobileNav: FC<IProps> = (props) => {
    const { open, onClose, sections = [] } = props;
    const pathname = usePathname();


    return (
        <Drawer anchor="left" onClose={onClose} open={open} variant="temporary" PaperProps={{ sx: { backgroundColor: '#F8F9FA', width: MOBILE_NAV_WIDTH, } }}>
            <Scrollbar sx={{ height: '100%', '& .simplebar-content': { height: '100%' }, '& .simplebar-placeholder': { height: '1px !important' } }}>

                <Stack sx={{ height: 'calc(100% - 65px)' }}>
                    <Stack component="nav" spacing={2} sx={{ flexGrow: 1, px: 2, mt: 3 }}>
                        <OrganisationSwitch />
                        <Divider />

                        {sections.map((section, index) => (
                            <MobileNavSection
                                items={section.items}
                                key={index}
                                pathname={pathname}
                                subheader={section.subheader}
                            />
                        ))}
                    </Stack>
                </Stack>
            </Scrollbar>
        </Drawer>
    );
};

MobileNav.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
    sections: PropTypes.array
};
