import { useCallback, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Box, Button, Card, CardContent, CardHeader, Checkbox, Divider, FormHelperText, Link, Stack, TextField, Typography } from '@mui/material';
import { RouterLink } from 'components/router-link';
import { Seo } from 'components/seo';
import type { AuthContextType } from 'contexts/firebase-context';
import { useAuth } from 'hooks/use-auth';
import { useMounted } from 'hooks/use-mounted';
import { usePageView } from 'hooks/use-page-view';
import { useSearchParams } from 'hooks/use-search-params';
import { paths } from 'paths';
import type { Page as PageType } from 'types/page';

interface Values {
    email: string;
    password: string;
    policy: boolean;
    submit: null;
}

const initialValues: Values = {
    email: '',
    password: '',
    policy: true,
    submit: null
};

const validationSchema = Yup.object({
    email: Yup
        .string()
        .email('Must be a valid email')
        .max(255)
        .required('Email is required'),
    password: Yup
        .string()
        .min(7)
        .max(255)
        .required('Password is required'),
    policy: Yup
        .boolean()
        .oneOf([true], 'This field must be checked')
});

const Page: PageType = () => {
    const isMounted = useMounted();
    const searchParams = useSearchParams();
    const returnTo = searchParams.get('returnTo');
    const { createUserWithEmailAndPassword, signInWithGoogle } = useAuth<AuthContextType>();
    const [accountCreated, setAccountCreated] = useState(false);

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, helpers): Promise<void> => {
            try {
                await createUserWithEmailAndPassword(values.email, values.password);

                if (isMounted()) {
                    setAccountCreated(true);
                }
            } catch (err: any) {
                let errorMessage = "An error occurred, please try again."

                if (err.code === "auth/email-already-in-use")
                    errorMessage = "This email is already in registered.  Please try logging in.";

                if (isMounted()) {
                    helpers.setStatus({ success: false });
                    helpers.setErrors({ submit: errorMessage });
                    helpers.setSubmitting(false);
                }
            }
        }
    });

    const handleGoogleClick = useCallback(
        async (): Promise<void> => {
            try {
                await signInWithGoogle();

                if (isMounted()) {
                    // returnTo could be an absolute path
                    window.location.href = returnTo || paths.index;
                }
            } catch (err) {
                console.error(err);
            }
        },
        [signInWithGoogle, isMounted, returnTo]
    );

    usePageView();

    if (accountCreated) {
        return (
            <>
                <Seo title="Register" />
                <div>
                    <Card elevation={16}>
                        <CardHeader
                            sx={{ pb: 0 }}
                            title="Account Created"
                        />
                        <CardContent>
                            <Typography color="text.secondary" variant="body2">
                                Please check your email for a verification link to activate your account.
                            </Typography>
                            <Typography color="text.secondary" variant="body2">
                                <br />
                                In some instances, <strong>check your Junk folder</strong> if you do not see the email in your inbox.
                            </Typography>
                        </CardContent>
                    </Card>
                </div>
            </>
        );
    }

    return (
        <>
            <Seo title="Register" />
            <div>
                <Card elevation={16}>

                    <CardHeader
                        subheader={(
                            <Typography color="text.secondary" variant="body2" sx={{ fontSize: 16, mt: 2 }}>
                                Already have an account?
                                &nbsp;
                                <Link component={RouterLink} href={paths.auth.login} underline="hover" variant="subtitle2" sx={{ fontSize: 16, fontWeight: 'bold' }}>
                                    Click here to Sign In
                                </Link>
                            </Typography>
                        )}
                        sx={{ pb: 0 }}
                        titleTypographyProps={{ variant: 'h4' }}
                        title="Register"
                    />

                    <CardContent>

                        {accountCreated && (
                            <Typography color="text.secondary" variant="body2">
                                Account created!  Please check your email for a verification link.
                            </Typography>
                        )}

                        {!accountCreated && (
                            <form noValidate onSubmit={formik.handleSubmit}>
                                <Box sx={{ flexGrow: 1, mt: 3 }}>
                                    <Button
                                        fullWidth
                                        onClick={handleGoogleClick}
                                        size="large"
                                        sx={{
                                            backgroundColor: 'common.white',
                                            color: 'common.black',
                                            '&:hover': {
                                                backgroundColor: 'common.white',
                                                color: 'common.black'
                                            }
                                        }}
                                        variant="contained">
                                        <Box alt="Google" component="img" src="/assets/logos/logo-google.svg" sx={{ mr: 1 }} />
                                        Sign up with Google
                                    </Button>

                                    <Box sx={{ alignItems: 'center', display: 'flex', mt: 2 }}>
                                        <Box sx={{ flexGrow: 1 }}>
                                            <Divider orientation="horizontal" />
                                        </Box>

                                        <Typography color="text.secondary" sx={{ m: 2 }} variant="body1">
                                            OR
                                        </Typography>

                                        <Box sx={{ flexGrow: 1 }}>
                                            <Divider orientation="horizontal" />
                                        </Box>
                                    </Box>
                                </Box>

                                <Stack spacing={3}>
                                    <TextField
                                        error={!!(formik.touched.email && formik.errors.email)}
                                        fullWidth
                                        helperText={formik.touched.email && formik.errors.email}
                                        label="Email Address"
                                        name="email"
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        type="email"
                                        value={formik.values.email}
                                    />
                                    <TextField
                                        error={!!(formik.touched.password && formik.errors.password)}
                                        fullWidth
                                        helperText={formik.touched.password && formik.errors.password}
                                        label="Password"
                                        name="password"
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        type="password"
                                        value={formik.values.password}
                                    />
                                </Stack>
                                <Box sx={{ alignItems: 'center', display: 'flex', ml: -1, mt: 1 }}>
                                    <Checkbox checked={formik.values.policy} name="policy" onChange={formik.handleChange} />

                                    <Typography color="text.secondary" variant="body2">
                                        I have read the
                                        {' '}
                                        <Link component="a" href="#">
                                            Terms and Conditions
                                        </Link>
                                    </Typography>
                                </Box>

                                {!!(formik.touched.policy && formik.errors.policy) && (
                                    <FormHelperText error>
                                        {formik.errors.policy}
                                    </FormHelperText>
                                )}

                                {formik.errors.submit && (
                                    <FormHelperText
                                        error
                                        sx={{ mt: 3 }}
                                    >
                                        {formik.errors.submit as string}
                                    </FormHelperText>
                                )}

                                <Box sx={{ mt: 2 }}>
                                    <Button disabled={formik.isSubmitting} fullWidth size="large" type="submit" variant="contained">
                                        Register
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </CardContent>
                </Card>
            </div>
        </>
    );
};

export default Page;
