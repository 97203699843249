import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { withAuthGuard } from 'hocs/with-auth-guard';
import { VerticalLayout } from './vertical-layout';

interface LayoutProps {
    children?: ReactNode;
}

export const Layout: FC<LayoutProps> = withAuthGuard((props) => {

    return (
        <VerticalLayout
            {...props}
        />
    );
});

Layout.propTypes = {
    children: PropTypes.node
};
