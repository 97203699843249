import { ChangeEvent, MouseEvent, useCallback, useState } from 'react';
import { useMounted } from "../use-mounted";
import PeerdwebService from "services/peerdweb/peerdweb-service";
import { Asset } from 'types/assets/asset';

export interface AssetStoreSearchFilters {
    searchText?: string;
    project?: string[];
    status?: string[];
    owner?: string[];
}

export interface AssetStoreSearchState {
    filters: AssetStoreSearchFilters;
    page: number;
    rowsPerPage: number;
}

interface AssetStoreState {
    assets: Asset[];
    assetsCount: number;
}

export const useAssetStore = (searchState: AssetStoreSearchState) => {
    const isMounted = useMounted();

    const [state, setState] = useState<AssetStoreState>({
        assets: [],
        assetsCount: 0
    });

    const peerdwebService = new PeerdwebService();

    const searchAssets = useCallback(async () => {
        try {
            const response = await peerdwebService.searchAssets({
                ...searchState.filters,
                page: searchState.page,
                pageSize: searchState.rowsPerPage,
            });

            if (isMounted()) {
                setState({
                    assets: response.data.assets,
                    assetsCount: response.data.total
                });
            }
        } catch (err) {
            console.error(err);
        }
    }, [searchState, isMounted]);

    // tbh, this can be reworked to a standard search
    const getAssetsCount = useCallback(async (): Promise<number> => {
        var publishedCount = 0;
        try {
            const response = await peerdwebService.searchAssets({
                page: 0,
                pageSize: 1,
            });
            publishedCount = response.data.total;
        } catch (err) {
            console.error(err);
        }
        return publishedCount;
    }, [searchState, isMounted]);

    // wasn't keen on this being in the store
    // useEffect(() => {
    //     searchAssets();
    // }, [searchState]);

    return {
        ...state,
        setState,
        getPublishedCount: getAssetsCount,
        searchAssets
    };
};



export const useAssetsSearch = () => {
    const [state, setState] = useState<AssetStoreSearchState>({
        filters: {
            searchText: undefined,
            project: [],
            status: [],
            owner: []
        },
        page: 0,
        rowsPerPage: 1000
    });

    const handleFiltersChange = useCallback((filters: AssetStoreSearchFilters): void => {
        setState((prevState) => ({
            ...prevState,
            filters
        }));
    }, []);

    const handlePageChange = useCallback((event: MouseEvent<HTMLButtonElement> | null, page: number): void => {
        setState((prevState) => ({
            ...prevState,
            page
        }));
    }, []);

    const handleRowsPerPageChange = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState) => ({
            ...prevState,
            rowsPerPage: parseInt(event.target.value, 10)
        }));
    }, []);

    return {
        handleFiltersChange,
        handlePageChange,
        handleRowsPerPageChange,
        state
    };
};


