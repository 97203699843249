import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Box, Stack } from '@mui/material';

interface LayoutProps {
    children: ReactNode;
}

export const Layout: FC<LayoutProps> = (props) => {
    const { children } = props;

    return (
        <Box sx={{
            backgroundColor: '#F0F0FF',
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            <Box sx={{ p: 2, width: 500, maxWidth: '100%' }}>
                <div>
                    <Box sx={{ mb: 4 }}>
                        <Stack display="flex" alignItems="center" alignContent="center"                        >
                            <img src='/images/peerdweb-logo-long.png' alt='Peerdweb' style={{ maxHeight: '64px', maxWidth: '176px' }} />
                        </Stack>
                    </Box>
                    {children}
                </div>
            </Box>
        </Box>
    );
};

Layout.propTypes = {
    children: PropTypes.node
};
