import { useEffect, type FC } from 'react';
import { Box, Button, Card, CardActions, Divider, Link, Stack, SvgIcon, Tooltip, Typography } from '@mui/material';
import { paths } from 'paths';
import { RouterLink } from 'components/router-link';
import ArrowRightIcon from '@untitled-ui/icons-react/build/esm/ArrowRight';
import ShareIcon from '@mui/icons-material/Share';
import { PeerdwebColours } from 'theme/peerdweb-colours';
import useSmartAccessStore from 'hooks/smart-access/useSmartAccessStore';
import SouthWestIcon from '@mui/icons-material/SouthWest';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import PuffLoader from 'react-spinners/PuffLoader';

export const SmartAccessPill: FC = () => {
    const smartAccessStore = useSmartAccessStore()

    useEffect(() => {
        smartAccessStore.getIncomingSmartAccessList();
        smartAccessStore.getOutgoingSmartAccessList();
    }, []);

    return (
        <Card>
            <Stack alignItems="center" direction='row' spacing={3} sx={{ px: 4, py: 3 }}>
                <div>
                    <ShareIcon sx={{ display: "inline-flex", color: PeerdwebColours.NeonBlue, alignItems: "center", width: 48, height: 48 }} />
                </div>
                <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
                    <Typography color="text.secondary" variant="body2">
                        Smart Access
                    </Typography>
                    <Stack direction='row' spacing={1} sx={{ justifyContent: 'space-around' }}>
                        <Tooltip title="Shared with you" arrow placement="top">
                            <Typography color="text.primary" variant="h4" sx={{ display: 'flex', alignItems: 'baseline' }}>
                                {smartAccessStore.incomingCount} <SouthWestIcon />
                            </Typography>
                        </Tooltip>
                        <Tooltip title="Your asset shares" arrow>
                            <Typography color="text.primary" variant="h4" sx={{ display: 'flex', alignItems: 'baseline' }}>
                                {smartAccessStore.outgoingCount} <NorthEastIcon />
                            </Typography>
                        </Tooltip>
                    </Stack>
                </Box>
            </Stack>
            <Divider />
            <CardActions sx={{ justifyContent: 'space-between' }}>
                <Button color="inherit" endIcon={(<SvgIcon><ArrowRightIcon /></SvgIcon>)} size="small" component={RouterLink} href={paths.smartAccess}>
                    Smart Access
                </Button>
                {smartAccessStore.inviteCount > 0 &&
                    <>
                        <Link href={paths.smartAccess} sx={{ display: 'flex' }}>
                            <PuffLoader size={20} color={PeerdwebColours.NeonBlue} />
                            <Typography color="text.secondary" variant="subtitle2" sx={{ px: 1 }}>
                                <strong>{smartAccessStore.inviteCount} Invite{smartAccessStore.inviteCount > 1 ? 's' : ''} Pending</strong>
                            </Typography>
                        </Link>
                    </>
                }
            </CardActions>
        </Card>
    );
};
