import type { ChangeEvent, FC, MouseEvent } from 'react';
import { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Checkbox, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import { Project } from 'types/project/project';


interface IProps {
    count?: number;
    items?: Project[];
    onPageChange?: (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
    onRowsPerPageChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    page?: number;
    pageSize?: number;
    projectSelectionChanged?: (projectId: string, name: string) => void;
    selectedProjectId: string;
    selectedProjectName: string;
}

export const ProjectListTable: FC<IProps> = (props) => {
    const {
        count = 0,
        items = [],
        onPageChange = () => { },
        onRowsPerPageChange,
        page = 0,
        pageSize = 0,
        projectSelectionChanged,
        selectedProjectId,
        selectedProjectName
    } = props;

    const [selected, setSelected] = useState<string>(selectedProjectId ?? '');
    const isSelected = (id: string) => selected.indexOf(id) !== -1;

    const handleClick = (event: React.MouseEvent<unknown>, id: string, name: string) => {
        if (selected === id) {
            setSelected('');
            projectSelectionChanged?.('', '');
            return;
        }
        setSelected(id);
        projectSelectionChanged?.(id, name);
    };

    useEffect(() => {
        setSelected(selectedProjectId ?? '');
    }, [selectedProjectId]);

    return (
        <Box>
            <Typography variant='h6'>
                <span style={{fontWeight: 100}}>Selected Project:</span> {selectedProjectName.length > 0 ? selectedProjectName : '-'}
            </Typography>
            <TableContainer component={Paper} sx={{ width: "100%", height: 400, overflowX: 'auto' }}>
                <Table stickyHeader sx={{ minWidth: 800 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell align='center' colSpan={2}>
                                Project
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items.map((project, index) => {
                            const isItemSelected = isSelected(project._id ?? '');
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return (
                                <Fragment key={project._id}>
                                    <TableRow
                                        hover
                                        key={project._id}
                                        onClick={(event) => handleClick(event, project._id ?? '', project.name)}
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        selected={isItemSelected}
                                        sx={{ cursor: 'pointer' }}
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                color="primary"
                                                checked={isItemSelected}
                                                inputProps={{
                                                    'aria-labelledby': labelId,
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="subtitle2" sx={{ cursor: 'pointer' }} onClick={() => { }}>
                                                {project.name}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </Fragment>
                            );
                        })}

                        {items.length == 0 &&
                            <TableRow>
                                <TableCell align='left' colSpan={2} sx={{ opacity: 0.5 }}>
                                    No projects found
                                </TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                component="div"
                count={count}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}
                page={page}
                rowsPerPage={pageSize}
                rowsPerPageOptions={[10, 25, 100]}
                labelRowsPerPage="Projects per page"
            />
        </Box>
    );
};

ProjectListTable.propTypes = {
    count: PropTypes.number,
    items: PropTypes.array,
    onPageChange: PropTypes.func,
    onRowsPerPageChange: PropTypes.func,
    page: PropTypes.number,
    pageSize: PropTypes.number
};
