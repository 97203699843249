import type { SVGProps } from 'react';

const Lock01 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M4 14.8c0-1.6802 0-2.5202.327-3.162a2.9997 2.9997 0 0 1 1.311-1.311C6.2798 10 7.1198 10 8.8 10h6.4c1.6802 0 2.5202 0 3.162.327a2.9994 2.9994 0 0 1 1.311 1.311C20 12.2798 20 13.1198 20 14.8v1.4c0 1.6802 0 2.5202-.327 3.162a2.9994 2.9994 0 0 1-1.311 1.311C17.7202 21 16.8802 21 15.2 21H8.8c-1.6802 0-2.5202 0-3.162-.327a2.9997 2.9997 0 0 1-1.311-1.311C4 18.7202 4 17.8802 4 16.2v-1.4Z"
      opacity={0.12}
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M17 10V8c0-2.7614-2.2386-5-5-5S7 5.2386 7 8v2m5 4.5v2M8.8 21h6.4c1.6802 0 2.5202 0 3.162-.327a2.9994 2.9994 0 0 0 1.311-1.311C20 18.7202 20 17.8802 20 16.2v-1.4c0-1.6802 0-2.5202-.327-3.162a2.9994 2.9994 0 0 0-1.311-1.311C17.7202 10 16.8802 10 15.2 10H8.8c-1.6802 0-2.5202 0-3.162.327a2.9997 2.9997 0 0 0-1.311 1.311C4 12.2798 4 13.1198 4 14.8v1.4c0 1.6802 0 2.5202.327 3.162a2.9997 2.9997 0 0 0 1.311 1.311C6.2798 21 7.1198 21 8.8 21Z"
    />
  </svg>
);

export default Lock01;
