import { IconButton, Link, ListItem, ListItemIcon, ListItemText, SvgIcon, Tooltip } from '@mui/material';
import { FileIcon } from 'components/file-icon';
import Hasher from 'core/hasher';
import { FC, useEffect, useState } from 'react';
import { FileWithPath } from 'react-dropzone';
import PeerdwebService from 'services/peerdweb/peerdweb-service';
import { bytesToSize } from 'utils/bytes-to-size';
import XIcon from '@untitled-ui/icons-react/build/esm/X';
import HashLoader from 'react-spinners/HashLoader';
import { Box, Stack } from '@mui/system';
import CheckCircle from '@untitled-ui/icons-react/build/esm/CheckCircle';
import XCircle from '@untitled-ui/icons-react/build/esm/XCircle';
import { ValidateAssetResponse } from 'types/validate/validate';
import { PeerdwebColours } from 'theme/peerdweb-colours';

interface IProps {
    file: FileWithPath;
    onRemove: (file: File) => void;
}

const ValidatedFileDropzoneFileDisplay: FC<IProps> = ({ file, onRemove }) => {
    const extension = file.name.split('.').pop();
    const [loading, setLoading] = useState<boolean>(true);
    const [validationResult, setValidationResult] = useState<string | null>(null);
    const [assetHash, setAssetHash] = useState<string | null>(null);
    const [validateTransactionResponse, setValidateTransactionResponse] = useState<ValidateAssetResponse | null>(null);
    const peerdwebService = new PeerdwebService();

    const toPrettyDateTime = (timestamp: Date) => {
        return `${timestamp?.toDateString()} at ${timestamp?.toLocaleTimeString()}`;
    }

    const toPrettyBlockchainName = (name: string) => {
        name = name.replace(/(\w)(\w*)/g, function (g0, g1, g2) { return g1.toUpperCase() + g2.toLowerCase(); });
        return name.replaceAll('-', ' ');
    }

    // TODO: lots of duplicate code here, refactor
    useEffect(() => {
        setLoading(true);

        if (extension === process.env.REACT_APP_PROTECTED_FILE_EXT || extension === 'peerdwebid') {
            Hasher.generate(file)
                .then(async (hash) => {
                    setAssetHash(hash);
                    const response = await peerdwebService.validateAssetHash(hash);
                    setValidateTransactionResponse(response.data);
                    setValidationResult(null);

                    if (response?.data.timestamp) {
                        setValidationResult(`Asset validated on ${toPrettyDateTime(new Date(response.data.timestamp))}`);
                    }
                })
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
        else {
            Hasher.generate(file)
                .then(async (hash) => {
                    setAssetHash(hash);
                    const searchResults = await peerdwebService.searchAssets({
                        assetFileHash: hash,
                        anonymous: true,
                    })

                    //TODO: could display for multiple assets but just doing latest for now
                    const asset = searchResults.data.assets[0];
                    var response;
                    try {
                        response = await peerdwebService.validateAssetHash(asset.files[0].hash);
                    } catch (error) {
                        // legacy mints
                        console.log("Legacy mint");
                        response = await peerdwebService.validateAssetHash(asset.zipHash);
                    }

                    setValidateTransactionResponse(response.data);
                    setValidationResult(null);

                    if (response.data.timestamp) {
                        setValidationResult(`Part of asset '${asset.headline}' validated on ${toPrettyDateTime(new Date(response.data.timestamp))}`);
                    }
                })
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => {
                    setLoading(false);
                });
        }

    }, [file]);

    return (
        <ListItem key={file.path} sx={{ border: 1, borderColor: 'divider', borderRadius: 1, '& + &': { mt: 1 } }}>
            <ListItemIcon>
                <FileIcon extension={extension} />
            </ListItemIcon>

            <ListItemText
                primary={file.name}
                primaryTypographyProps={{ variant: 'subtitle2' }}
                secondaryTypographyProps={{ variant: 'caption' }}
                secondary={
                    <>
                        {bytesToSize(file.size)}<br />
                        {loading &&
                            <Stack direction={'row'} spacing={0.5} alignItems={'center'} color={PeerdwebColours.NeonBlue}>
                                <HashLoader size={18} color={PeerdwebColours.NeonBlue} />
                                <span>Validating...</span>
                            </Stack>
                        }
                        {!loading &&
                            <>
                                {validationResult ?
                                    <Stack direction={'column'} spacing={0.5} alignItems={'left'} >
                                        <Stack color={"#24ba84"} direction={'row'} alignItems={"center"}>
                                            <CheckCircle />
                                            Protected
                                        </Stack>
                                        <Box>{validationResult}</Box>
                                        <Box>File hash: {assetHash}</Box>
                                        {validateTransactionResponse && <>
                                            <Box>
                                                Blockchain: <strong>{toPrettyBlockchainName(validateTransactionResponse?.blockchain ?? '')}</strong>
                                            </Box>
                                            <Box>
                                                Transaction:&nbsp;
                                                <Link href={validateTransactionResponse?.explorerUrl + 'tx/' + validateTransactionResponse?.txHash} target="_blank" rel="noopener noreferrer">
                                                    View on explorer
                                                </Link>
                                            </Box>
                                        </>}
                                    </Stack>
                                    :
                                    <Stack direction={'column'} spacing={0.5} alignItems={'left'}>
                                        <Stack color={"#e51546"} direction={'row'} alignItems={"center"}>
                                            <XCircle />
                                            Unprotected
                                        </Stack>
                                        <Box>File hash: {assetHash}</Box>
                                    </Stack>
                                }
                            </>
                        }
                    </>
                }
            />

            <Tooltip title="Remove">
                <IconButton edge="end" onClick={() => onRemove?.(file)}>
                    <SvgIcon>
                        <XIcon />
                    </SvgIcon>
                </IconButton>
            </Tooltip>
        </ListItem >
    );
};


export default ValidatedFileDropzoneFileDisplay;