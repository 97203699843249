import { useCallback } from "react";
import seq, { SeqEvent } from 'seq-logging';

export const useLogger = () => {
    const seqUri = process.env.REACT_APP_SEQ_URI as string;
    const seqApiKey = process.env.REACT_APP_SEQ_API_KEY as string;
    const logger = new seq.Logger({
        serverUrl: seqUri,
        apiKey: seqApiKey,
        onError: (e => {
            console.error(`An error occurred whilst connecting to Seq at ${seqUri}:\n`, e)
        }),
    });

    const log = useCallback((email: string, message: string, level: SeqEvent["level"] = 'Information', properties: object = {}): void => {
        logger.emit({
            timestamp: new Date(),
            level,
            messageTemplate: email + ' // ' + message,
            properties: { ...properties, email }
        });
    }, [logger]);

    return [log];

};

