import type { FC } from 'react';
import { Avatar, ListItemAvatar, MenuItem, Popover } from '@mui/material';
import { Organisation } from 'types/organisation/organisation';
import { displayInitials } from 'utils/pretty-pretty';

interface IProps {
    anchorEl: null | Element;
    onChange?: (organisation: string) => void;
    onClose?: () => void;
    open?: boolean;
    organisations: Organisation[];
}

export const OrganisationPopover: FC<IProps> = (props) => {
    const { anchorEl, onChange, onClose, open = false, organisations, ...other } = props;

    return (
        <Popover
            anchorEl={anchorEl}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            disableScrollLock
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            keepMounted
            onClose={onClose}
            open={open}
            PaperProps={{ sx: { width: 'auto', mt: '10px' }, elevation: 10 }}
            {...other}
        >
            {organisations.map((organisation) => (
                <MenuItem key={organisation._id} sx={{ p: 2 }} onClick={() => onChange?.(organisation._id ?? '')}>
                    <ListItemAvatar>
                        <Avatar {...displayInitials(organisation?.name ?? 'PW')} variant="rounded" />
                    </ListItemAvatar>
                    {organisation.name}
                </MenuItem>
            ))}
        </Popover>
    );
};
