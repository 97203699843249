import type { RouteObject } from 'react-router';
import { Outlet } from 'react-router-dom';
import { IssuerGuard } from 'guards/issuer-guard';
import { GuestGuard } from 'guards/guest-guard';
import { Layout as AuthLayout } from 'layouts/auth/auth-layout';
import { Issuer } from 'utils/auth';
import FirebaseLoginPage from 'pages/auth/login';
import FirebaseRegisterPage from 'pages/auth/register';
import FirebaseActionPage from 'pages/auth/action';

export const authRoutes: RouteObject[] = [
    {
        path: 'auth',
        element: (
            <IssuerGuard issuer={Issuer.Firebase}>
                <GuestGuard>
                    <AuthLayout>
                        <Outlet />
                    </AuthLayout>
                </GuestGuard>
            </IssuerGuard>
        ),
        children: [
            {
                path: 'login',
                element: <FirebaseLoginPage />
            },
            {
                path: 'register',
                element: <FirebaseRegisterPage />
            },
            {
                path: 'action',
                element: <FirebaseActionPage />
            },
        ]
    }
];
