import { Box, Container, Modal, Stack, Typography, Unstable_Grid2 as Grid, Divider, Button } from '@mui/material';
import { Seo } from 'components/seo';
import { usePageView } from 'hooks/use-page-view';
import { ProtectedAssetsSummary } from 'components/dashboard/protected-assets-summary';
import type { Page as PageType } from 'types/page';
import { useAuth } from 'hooks/use-auth';
import { useEffect, useState } from 'react';
import { SmartAccessPill } from 'components/dashboard/smart-access-pill';
import { useLogger } from 'hooks/use-logger';
import { ProjectsSummary } from 'components/dashboard/projects-summary';
import { DownloadedAssetsChart } from 'components/dashboard/downloaded-assets-chart';
import { DownloadedUsersChart } from 'components/dashboard/downloaded-users-chart';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 350,
    width: { xs: 350, md: 600 },
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: { xs: 2, md: 6 },
};

const HomePage: PageType = () => {
    const auth = useAuth();
    const { organisation } = useAuth();

    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);
    const params = new URLSearchParams(window.location.search)
    let signedIn = params.get('signedIn')

    usePageView();

    const [log] = useLogger();
    useEffect(() => {
        log(auth.authUser?.email ?? '', "Home");
        if (signedIn)
            setOpen(true);
    }, []);

    return (
        <>
            <Seo title="Peerdweb: Home" />
            <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
                <Container maxWidth="xl">
                    <Stack spacing={3} sx={{ mb: 3 }}>
                        <Typography variant="h4">
                            Home
                        </Typography>
                        <Divider />

                        <Grid container spacing={{ xs: 3, lg: 4 }} sx={{ pb: 3 }}>
                            <Grid xs={12} md={4}>
                                <ProtectedAssetsSummary />
                            </Grid>
                            <Grid xs={12} md={4}>
                                <ProjectsSummary />
                            </Grid>
                            <Grid xs={12} md={4}>
                                <SmartAccessPill />
                            </Grid>
                        </Grid>

                        <Grid container spacing={{ xs: 3, lg: 4 }} sx={{ pb: 3 }}>
                            <Grid xs={12} md={6}>
                                <DownloadedAssetsChart />
                            </Grid>
                            <Grid xs={12} md={6}>
                                <DownloadedUsersChart />
                            </Grid>
                        </Grid>

                    </Stack>
                </Container>
            </Box>
            {signedIn &&
                <div>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            <Typography id="modal-modal-title" variant="h4" component="h2" sx={{ mb: 2 }}>
                                {organisation?.name} Obligation
                            </Typography>

                            <div id="modal-modal-description" dangerouslySetInnerHTML={{ __html: organisation?.obligationHtml ?? '' }} />

                            <Stack direction={'row'} spacing={2} sx={{ justifyContent: 'center', mt: 8, mb: 2 }}>
                                <Button variant="contained" style={{ maxWidth: 300 }} onClick={() => { handleClose(); }} >
                                    Acknowledge
                                </Button>
                            </Stack>
                        </Box>
                    </Modal>
                </div>
            }
        </>
    );
};

export default HomePage;
