import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import type { Theme } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { Section } from '../config';
import { MobileNav } from '../mobile-nav';
import { SideNav } from './side-nav';
import { TopNav } from './top-nav';
import { useMobileNav } from './use-mobile-nav';
import { useMenuSections } from '../config';
import { Footer } from '../footer/footer';

const SIDE_NAV_WIDTH: number = 280;

const VerticalLayoutRoot = styled('div')(
    ({ theme }) => ({
        display: 'flex',
        flex: '1 1 auto',
        maxWidth: '100%',
        [theme.breakpoints.up('lg')]: {
            paddingLeft: SIDE_NAV_WIDTH
        }
    })
);

const VerticalLayoutContainer = styled('div')({
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    width: '100%'
});

interface VerticalLayoutProps {
    children?: ReactNode;
    sections?: Section[];
}

export const VerticalLayout: FC<VerticalLayoutProps> = (props) => {
    const { children } = props;
    const sections = useMenuSections();

    const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
    const mobileNav = useMobileNav();

    return (
        <>
            <TopNav onMobileNavOpen={mobileNav.handleOpen} />

            {lgUp && <SideNav sections={sections} />}

            {!lgUp && <MobileNav onClose={mobileNav.handleClose} open={mobileNav.open} sections={sections} />}

            <VerticalLayoutRoot>
                <VerticalLayoutContainer>
                    {children}
                    <Footer />
                </VerticalLayoutContainer>
            </VerticalLayoutRoot>

        </>
    );
};

VerticalLayout.propTypes = {
    children: PropTypes.node,
    sections: PropTypes.array
};
