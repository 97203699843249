import { FC } from 'react';
import { useState } from 'react';
import { Button, Dialog, DialogContent, DialogProps, Divider, Grid, IconButton, Stack, SvgIcon, Typography } from '@mui/material';
import PlusIcon from '@untitled-ui/icons-react/build/esm/Plus';
import { ViewProjectList } from '../projects/view-project-list';
import CloseIcon from '@mui/icons-material/Close';

interface IProps {
    projectSelected: (id: string, name: string) => void;
}

const ProjectDialog: FC<IProps> = ({ projectSelected }) => {
    const [open, setOpen] = useState(false);
    const [maxWidth] = useState<DialogProps['maxWidth']>('md');
    const [selectedProjectId, setSelectedProjectId] = useState<string>('');
    const [selectedProjectName, setSelectedProjectName] = useState<string>('');

    const handleDialogOpen = () => {
        setOpen(true);
    };

    const handleConfirmClose = () => {
        setOpen(false);
        projectSelected(selectedProjectId, selectedProjectName);
    };

    const handleCancelClose = () => {
        setOpen(false);
    };

    const handleProjectSelected = (id: string, name: string) => {
        setSelectedProjectId(id);
        setSelectedProjectName(name);
    }

    return (
        <>
            <Button startIcon={(<SvgIcon><PlusIcon /></SvgIcon>)} variant='outlined' color='info' onClick={handleDialogOpen} >
                Add to Project
            </Button>
            <Dialog open={open} onClose={handleConfirmClose} maxWidth={maxWidth} scroll='paper' aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <IconButton aria-label="close" onClick={handleCancelClose} sx={{ position: 'absolute', right: 8, top: 8 }}>
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    <Stack spacing={1}>
                        <Typography variant='h6'>
                            Add to Project
                        </Typography>
                        <Divider />

                        <ViewProjectList projectSelectionChanged={handleProjectSelected} selectedProjectId={selectedProjectId} selectedProjectName={selectedProjectName} />

                        <Grid container sx={{ pt: 5 }}>
                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }} >
                                <Button onClick={handleConfirmClose} variant='contained' color="primary" disabled={selectedProjectId == '' ? true : false}>
                                    Select Project: {selectedProjectName}
                                </Button>
                            </Grid>
                        </Grid>

                    </Stack>
                </DialogContent >
            </Dialog >
        </>
    );
};

export default ProjectDialog;