import { Avatar, Card, CardContent, Divider, Link, Stack, SvgIcon, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import User01Icon from '@untitled-ui/icons-react/build/esm/User01';
import { Scrollbar } from 'components/scrollbar';
import { SeverityPill } from 'components/severity-pill';
import { usePageView } from 'hooks/use-page-view';
import { useSuperUserUsersStore } from 'hooks/super-admin/useSuperUserUsersStore';
import type { Page as PageType } from 'types/page';

const SuperAdminUsers: PageType = () => {
    const allUsers = useSuperUserUsersStore();

    usePageView();

    return (
        <Stack spacing={4}>
            <Stack direction="row" justifyContent="space-between" spacing={4}>
                <Stack spacing={1}>
                    <Typography variant="h4">
                        Users ({allUsers.total})
                    </Typography>
                </Stack>
            </Stack>

            <Card>
                <CardContent>
                    <Scrollbar sx={{ '& .simplebar-placeholder': { height: '1px !important' } }}>
                        <Table sx={{ minWidth: 400 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        User
                                    </TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {allUsers.users?.map((user, index) => (
                                    <TableRow key={index}>
                                        <TableCell>
                                            <Stack alignItems="center" direction="row" spacing={1}>
                                                <Avatar src={user.imageUrl} sx={{ height: 40, width: 40 }}>
                                                    <SvgIcon>
                                                        <User01Icon />
                                                    </SvgIcon>
                                                </Avatar>
                                                <div>
                                                    <Typography variant="body1">
                                                        {user.displayName}
                                                        {
                                                            user.superAdmin
                                                                ? (
                                                                    <SeverityPill color='error'>
                                                                        Super
                                                                    </SeverityPill>
                                                                )
                                                                : user.email.includes('@nabtec.co.uk')
                                                                    ? (
                                                                        <SeverityPill color='secondary'>
                                                                            Nabtec
                                                                        </SeverityPill>
                                                                    )
                                                                    : ''
                                                        }

                                                    </Typography>
                                                    <Typography color="text.secondary" variant="body2">
                                                        <Link href={`mailto:${user.email}`}>{user.email}</Link><br />
                                                        User IP: {user.ip}
                                                    </Typography>
                                                    <Divider sx={{ my: 1 }} />
                                                    <Typography color="text.secondary" variant="body2">
                                                        Firebase ID: {user.firebaseUid}<br />
                                                        _id: {user._id}
                                                    </Typography>
                                                    <Divider sx={{ my: 1 }} />
                                                    <Typography color="text.secondary" variant="body2">
                                                        Created: {user.createdAt && new Date(user.createdAt).toLocaleString('en-GB')}<br />
                                                        Last seen: {user.lastLoggedInAt && new Date(user.lastLoggedInAt).toLocaleString('en-GB')}
                                                    </Typography>
                                                </div>
                                            </Stack>
                                        </TableCell>
                                        <TableCell>
                                            Assets: {user.assets?.length}<br />
                                            {user.assets?.map((asset, index) => (
                                                <span key={index}>
                                                    {asset.headline} <br />
                                                </span>
                                            ))}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Scrollbar>
                </CardContent>
            </Card>
        </Stack>
    );
};

export default SuperAdminUsers;
