import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, Drawer, Stack } from '@mui/material';
import { RouterLink } from 'components/router-link';
import { Scrollbar } from 'components/scrollbar';
import { usePathname } from 'hooks/use-pathname';
import { paths } from 'paths';
import type { Section } from '../config';
import { SideNavSection } from './side-nav-section';
import { OrganisationSwitch } from 'components/organisation-switch';

const SIDE_NAV_WIDTH: number = 280;

interface IProps {
    sections?: Section[];
}

export const SideNav: FC<IProps> = (props) => {
    const { sections = [] } = props;
    const pathname = usePathname();

    return (
        <Drawer anchor="left" open variant="permanent"
            PaperProps={{
                sx: {
                    backgroundColor: '#F8F9FA', borderRightColor: '#F0F0FF', borderRightStyle: 'solid',
                    borderRightWidth: 3, width: SIDE_NAV_WIDTH, top: 0
                }
            }}>

            <Scrollbar
                sx={{
                    height: '100%', '& .simplebar-content': { height: '100%' }, '& .simplebar-scrollbar:before': { background: 'purple' },
                    '& .simplebar-placeholder': { height: '1px !important' },
                }}>
                <Stack>
                    <Box component={RouterLink} href={paths.index}
                        sx={{ display: 'flex', height: 40, p: '4px', width: { xs: '40px', sm: '100%' }, justifyContent: 'left', mb: 1, mt: 1, pl: '28px' }}>
                        <img src='/images/peerdweb-logo-long.png' alt='Peerdweb' style={{ height: '30px' }} />
                    </Box>

                    <Stack component="nav" spacing={2} sx={{ flexGrow: 1, px: 2, mt: 2 }}>
                        <OrganisationSwitch />
                        <Divider />

                        {sections.map((section, index) => (
                            <SideNavSection
                                items={section.items}
                                key={index}
                                pathname={pathname}
                                subheader={section.subheader}
                            />
                        ))}
                    </Stack>
                </Stack>
            </Scrollbar>
        </Drawer>
    );
};

SideNav.propTypes = {
    sections: PropTypes.array
};
