import { FC } from 'react';
import { useState } from 'react';
import { Backdrop, Box, Button, Divider, Link, Modal, Paper, Stack, Typography } from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Asset, AssetStorage } from 'types/assets/asset';
import { useAuth } from 'hooks/use-auth';
import PeerdwebService from 'services/peerdweb/peerdweb-service';
import { PeerdwebColours } from 'theme/peerdweb-colours';
import HashLoader from 'react-spinners/HashLoader';
import PuffLoader from 'react-spinners/PuffLoader';
import CheckBoxOutlineBlankTwoToneIcon from '@mui/icons-material/CheckBoxOutlineBlankTwoTone';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { toast } from 'react-hot-toast';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 440,
    width: { xs: 440, md: 600 },
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 6,
};

interface IProps {
    asset?: Asset;
    assetStorage?: AssetStorage;
}

const ObligationDialog: FC<IProps> = ({ asset, assetStorage }) => {
    const { peerdwebUser, organisation } = useAuth();
    const [downloadLink, setDownloadLink] = useState<string | null>(null);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const peerdwebService = new PeerdwebService();
    const [showPleaseWait, setShowPleaseWait] = useState(false);
    const [mintComplete, setMintComplete] = useState(false);

    const download = async () => {
        handleClose();
        if (!peerdwebUser || peerdwebUser._id === undefined) return;
        if (!asset || asset._id === undefined) return;
        if (!organisation || organisation.obligationHtml === undefined) return;

        try {
            setShowPleaseWait(true);
            setMintComplete(false);

            await peerdwebService.downloadAssetSmartAccess(asset._id, peerdwebUser._id, organisation.obligationHtml);
            setMintComplete(true);

            // TODO - *now* we want to fetch this storage location (or return from above), not have it anywhere until now
            setDownloadLink(assetStorage?.location || '');

        } catch (error: any) {
            toast.error("Asset access creation failed: " + error.message);
        }
        finally {
            HideWhenComplete();
        }
    };

    const HideWhenComplete = () => {
        setTimeout(() => {
            setShowPleaseWait(false);
        }, 2000);
    }


    return (
        <>
            <Typography variant="h4" sx={{ mb: 3 }}>
                {!downloadLink && <>Access Asset</>}
                {downloadLink && <>Granted Access Logged</>}
            </Typography>
            <Stack spacing={1}>
                {!downloadLink &&
                    <div>
                        <Button startIcon={(<CloudDownloadIcon />)} variant="contained" style={{ maxWidth: 220 }} disabled={showPleaseWait || organisation?.credits === 0} onClick={handleOpen}>Access Asset</Button>
                        <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style}>
                                <Typography id="modal-modal-title" variant="h4" component="h2" sx={{ mb: 2 }}>
                                    {organisation?.name} Obligation
                                </Typography>

                                <div id="modal-modal-description" dangerouslySetInnerHTML={{ __html: organisation?.obligationHtml ?? '' }} />

                                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} sx={{ alignItems: { xs: 'center', md: 'space-between' }, justifyContent: 'space-between', mt: 8 }}>
                                    <Button startIcon={(<ArrowBackIcon />)} variant="contained" color='error' style={{ maxWidth: 300 }} onClick={() => { handleClose(); }} >
                                        Go Back
                                    </Button>
                                    <Button startIcon={(<CloudDownloadIcon />)} variant="contained" style={{ maxWidth: 300 }} onClick={() => { download() }} >
                                        Accept & Access Asset
                                    </Button>
                                </Stack>
                            </Box>
                        </Modal>
                    </div>
                }

                {downloadLink &&
                    <Box sx={{ pb: 3 }}>
                        <Typography variant="body2" sx={{ fontSize: 16 }}>
                            Your access has been logged. Click the link below to view file in a separate browser tab or right-click and 'Save Link As...' to download.
                        </Typography>
                        <Stack direction={'row'} spacing={2} sx={{ mt: 2, alignItems: 'center' }}>
                            <Typography variant="body2" sx={{ fontSize: 16 }}>
                                Download link:
                            </Typography>
                            <Link sx={{ fontSize: 22, textDecoration: 'underline' }} href={downloadLink} target="_blank" rel="noopener noreferrer" download={assetStorage?.location}>{asset?.files[0].filename}</Link>
                        </Stack>
                    </Box>
                }

                <Backdrop open={showPleaseWait}>

                    <Paper sx={{ p: 4, width: 380, display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                            {!mintComplete ? <HashLoader size={32} color={PeerdwebColours.NeonBlue} /> : <PuffLoader size={32} color={PeerdwebColours.NabtecGreen} />}
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                            <Typography variant="body1" sx={{ my: 2 }}>
                                Please wait while we protect the asset.
                            </Typography>
                        </Box>
                        <Divider sx={{ mb: 2 }} />
                        <Typography variant="body2" marginBottom={2}>
                            This process may take up to 2 minutes, please <strong>do not refresh</strong> your browser.
                        </Typography>
                        <Divider sx={{ mb: 2 }} />
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Typography variant="body1">
                                Capture asset access:
                            </Typography>
                            <CheckBoxOutlinedIcon sx={{ color: PeerdwebColours.Navy }} />
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Typography variant="body1">
                                Mint to blockchain:
                            </Typography>
                            {!mintComplete ? <CheckBoxOutlineBlankTwoToneIcon sx={{ color: PeerdwebColours.Navy }} /> : <CheckBoxOutlinedIcon sx={{ color: PeerdwebColours.Navy }} />}
                        </Box>
                    </Paper>
                </Backdrop>
            </Stack>

        </>
    );
};

export default ObligationDialog;