import { Button, Card, CardContent, CardHeader, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { Seo } from 'components/seo';
import { useAuth } from 'hooks/use-auth';
import { usePageView } from 'hooks/use-page-view';
import { useSearchParams } from 'hooks/use-search-params';
import { paths } from 'paths';
import { useEffect, useState } from 'react';
import type { Page as PageType } from 'types/page';

const Page: PageType = () => {
    const searchParams = useSearchParams();
    const mode = searchParams.get('mode');
    const oneTimeCode = searchParams.get('oobCode');
    const [isValidRequest, setIsValidRequest] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const auth = useAuth();

    useEffect(() => {

        auth.signOut();

        if (!mode || !oneTimeCode) {
            handleInvalidRequest("Invalid request please try link again");
            return;
        }

        // Only handling verify email for now but probably would want to route to different pages for each mode in the future
        switch (mode) {
            case 'verifyEmail':
                handleVerifyEmail(oneTimeCode);
                break;
            case 'resetPassword':
                handleInvalidRequest("Unrecognized mode");
                break;
            case 'recoverEmail':
                handleInvalidRequest("Unrecognized mode");
                break;
            default:
                handleInvalidRequest("Unrecognized mode");
                break;
        }
    }, []);

    const handleVerifyEmail = (oneTimeCode: string) => {
        auth.verifyEmail(oneTimeCode)
            .then(() => {
                setIsValidRequest(true);
            })
            .catch((err: any) => {
                if (err.code === 'auth/invalid-action-code') {
                    handleInvalidRequest("Link is invalid/expired please try again");
                    return;
                }

                if (err.code === 'auth/expired-action-code') {
                    handleInvalidRequest("Link expired please try again");
                    return;
                }

                handleInvalidRequest("An error occurred please try again");
            });
    }

    const handleInvalidRequest = (errorMessage: string) => {
        setIsValidRequest(false);
        setErrorMessage(errorMessage);
    }

    usePageView();

    return (
        <>
            <Seo title="Email Verification" />
            <div>
                <Card elevation={16}>

                    <CardHeader
                        sx={{ pb: 0 }}
                        title="Verify Email"
                    />

                    <CardContent>
                        {isValidRequest
                            ?
                            <Stack spacing={2}>
                                <Typography variant="body2">
                                    Thank you for verifying your email address.
                                </Typography>

                                <Typography variant="body2">
                                    You can now sign in with your email and password.
                                </Typography>

                                <Button fullWidth size="large" type="button" variant="contained" href={paths.auth.login}>
                                    Log In
                                </Button>
                            </Stack>
                            :
                            <Stack spacing={2}>
                                <Typography variant="body2">
                                    {errorMessage}
                                </Typography>
                            </Stack>
                        }
                    </CardContent>
                </Card>
            </div>
        </>
    );
};

export default Page;

