import { FC, useCallback } from 'react';
import { Button, Stack, SvgIcon, Typography } from '@mui/material';
import { useState } from 'react';
import PeerdwebService from 'services/peerdweb/peerdweb-service';
import { toast } from 'react-hot-toast';
import { useAuth } from 'hooks/use-auth';
import { Asset, DisplayAdditionalField, UpdateAssetRequest, UpdateAssetValues } from "types/assets/asset";
import { useRouter } from 'hooks/use-router';
import { paths } from 'paths';
import { Form, Formik } from 'formik';
import { createAssetValidationSchema } from 'validation/assets/create-asset-validation-schema';
import { MandatoryFields } from '../asset-fields/mandatory-fields';
import { useLogger } from 'hooks/use-logger';
import AdditionalFieldsDialog from '../asset-fields/additional-fields-dialog';
import ProjectDialog from '../asset-fields/project-dialog';
import { AssetFields } from '../asset-fields/asset-fields';
import { stat } from 'fs';

const initialValues: UpdateAssetValues = {
    titleHeadline: '',
    abstract: ''
};

interface IProps {
    asset: Asset
}

export const UpdateAsset: FC<IProps> = ({ asset }) => {
    const [loading, setLoading] = useState(false);
    const peerdwebService = new PeerdwebService();

    const router = useRouter();
    const [log] = useLogger();

    const { getSubscriptionLevel, peerdwebUser, authUser } = useAuth();

    const [displayFields, setDisplayFields] = useState<DisplayAdditionalField>(asset.displayFields ?? {
        categorisation: false,
        tags: false,
        location: false
    });

    const [project, setProject] = useState<{ id: string, name: string }>({ id: asset.project?._id ?? '', name: asset.project?.name ?? '' });
    const [categorisation, setCategorisation] = useState<{ subject: string, format: string, product: string }>({ subject: asset.subject ?? '', format: asset.format ?? '', product: asset.product ?? '' });
    const [location, setLocation] = useState<string>(asset.location ?? '');
    const [status, setStatus] = useState<string>(asset.status ?? 'Active');
    const [tags, setTags] = useState<string[]>(asset.tags ?? []);
    const [assetValue, setAssetValue] = useState<string>(asset.assetValue ?? '');


    // TODO review this
    asset.headline && (initialValues.titleHeadline = asset.headline);
    asset.abstract && (initialValues.abstract = asset.abstract);

    const updateAsset = async (newAssetValues: UpdateAssetValues) => {
        if (peerdwebUser == null)
            throw new Error("User is not logged in");

        var updateAssetRequest: UpdateAssetRequest = {
            _id: asset._id || '',
            headline: newAssetValues.titleHeadline,
            abstract: newAssetValues.abstract,
            displayFields: displayFields,
            status: status,
            assetValue: assetValue
        };

        if (project.id) {
            updateAssetRequest.project = project.id;
        }
        else {
            updateAssetRequest.project = undefined;
        }

        if (displayFields.categorisation) {
            updateAssetRequest.subject = categorisation.subject;
            updateAssetRequest.format = categorisation.format;
            updateAssetRequest.product = categorisation.product;
        }

        if (displayFields.location) {
            updateAssetRequest.location = location
        }

        if (displayFields.tags) {
            updateAssetRequest.tags = tags;
        }

        await peerdwebService.updateAsset(updateAssetRequest);
    }

    const handleSubmit = async (values: UpdateAssetValues) => {
        setLoading(true);

        try {
            await updateAsset(values);
            toast.success("Asset updated successfully", { icon: "✅" });
            log(authUser?.email ?? '', "ACTION // updated asset " + asset.headline, "Warning", { assetId: asset._id });
            router.push(paths.assets.list);
        } catch (error: any) {
            toast.error("Asset update failed: " + error.message);
        }
        finally {
            setLoading(false);
        }
    };

    const handleFieldsToDisplay = useCallback((key: string, value: boolean): void => {
        const updatedDisplayFields = {
            ...displayFields,
            [key]: value,
        };
        setDisplayFields(updatedDisplayFields);
    }, [displayFields, setDisplayFields]);

    const handleProjectSelected = useCallback((projectId: string, name: string): void => {
        setProject({ id: projectId, name: name })
    }, [project, setProject]);

    const handleRemoveProject = useCallback(() => {
        setProject({ id: '', name: '' });
    }, [project, setProject]);

    const handleCategorisationChange = useCallback((categorisation: { subject: string, format: string, product: string }) => {
        setCategorisation({ subject: categorisation.subject, format: categorisation.format, product: categorisation.product });
    }, [categorisation, setCategorisation]);

    const handleLocationChange = useCallback((location: string) => {
        setLocation(location);
    }, [location, setLocation]);

    const handleStatusChange = useCallback((status: string) => {
        setStatus(status);
    }, [status, setStatus]);

    const handleAssetValueChange = useCallback((assetValue: string) => {
        setAssetValue(assetValue);
    }, [assetValue, setAssetValue]);

    // useEffect(() => {
    //     log(authUser?.email ?? '', "Update Asset // " + asset.headline, "Information", { assetId: id });
    // }, [authUser, asset.headline, log, id]);

    const handleTagsChange = useCallback((tags: string[]) => {
        setTags(tags);
    }, [tags, setTags]);

    return (
        <>
            <Typography variant="h4" sx={{ mb: 2 }}>
                Update Asset
            </Typography>

            <Formik initialValues={initialValues} validationSchema={createAssetValidationSchema} onSubmit={handleSubmit}>
                <Form>
                    <MandatoryFields />

                    <Stack spacing={3} sx={{ m: 3 }}>
                        <Stack direction="row" spacing={4}>
                            <AdditionalFieldsDialog displayFields={displayFields} updateFieldsToDisplay={handleFieldsToDisplay} />
                            <ProjectDialog projectSelected={handleProjectSelected} />
                        </Stack>
                    </Stack>

                    <AssetFields
                        displayFields={displayFields}
                        projectId={project.id}
                        projectName={project.name}
                        handleRemoveProject={handleRemoveProject}
                        handleCategorisationChange={handleCategorisationChange}
                        handleLocationChange={handleLocationChange}
                        handleStatusChange={handleStatusChange}
                        handleTagsChange={handleTagsChange}
                        categorisation={categorisation}
                        location={location}
                        status={status}
                        tags={tags}
                        assetValue={assetValue}
                        handleAssetValueChange={handleAssetValueChange}
                    />

                    <Stack direction={{ sx: "column", md: "row" }} sx={{ gap: 2, mt: 5 }}>
                        <Button endIcon={(<SvgIcon></SvgIcon>)} type="submit" variant="contained" disabled={loading} sx={{ minWidth: 200 }}>
                            Save
                        </Button>
                    </Stack>
                </Form>
            </Formik>

        </>
    );
};

