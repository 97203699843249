import { useEffect, useState, type FC } from 'react';
import ArrowRightIcon from '@untitled-ui/icons-react/build/esm/ArrowRight';
import { Box, Button, Card, CardActions, Divider, Stack, SvgIcon, Typography } from '@mui/material';
import { RouterLink } from 'components/router-link';
import { paths } from 'paths';
import GppGoodIcon from '@mui/icons-material/GppGood';
import { useAssetStore } from 'hooks/assets/useAssetStore';

export const ProtectedAssetsSummary: FC = () => {
    const assetsStore = useAssetStore({ filters: {}, page: 0, rowsPerPage: 1 });
    const [publishedCount, setPublishedCount] = useState<number | null>(null);

    useEffect(() => {
        const fetchPublishedCount = async () => {
            try {
                const count = await assetsStore.getPublishedCount();
                setPublishedCount(count);
            } catch (error) {
                console.error("Error fetching published count:", error);
            }
        };
        fetchPublishedCount();
    }, [assetsStore]);

    return (
        <Card>
            <Stack alignItems="center" direction='row' spacing={3} sx={{ px: 4, py: 3 }}>
                <div>
                    <GppGoodIcon sx={{ display: "inline-flex", color: "#24ba84", alignItems: "center", width: 48, height: 48 }} />
                </div>
                <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
                    <Typography color="text.secondary" variant="body2">
                        Protected Assets
                    </Typography>
                    <Typography color="text.primary" variant="h4">
                        {publishedCount !== null ? publishedCount.toString() : '...'}
                    </Typography>
                </Box>
            </Stack>
            <Divider />
            <CardActions>
                <Button color="inherit" endIcon={(<SvgIcon><ArrowRightIcon /></SvgIcon>)} size="small"
                    component={RouterLink}
                    href={paths.assets.list}
                >
                    View Assets
                </Button>
            </CardActions>
        </Card>
    );
};
