import { FC } from 'react';
import { Stack, TextField } from '@mui/material';
import { QuillEditor } from 'components/quill-editor';
import { useFormikContext } from 'formik';
import { NewAssetValues } from "types/assets/asset";

export const MandatoryFields: FC = () => {
    const formik = useFormikContext<NewAssetValues>();

    return (
        <>
            <Stack spacing={3}>
                <TextField
                    error={!!(formik.touched.titleHeadline && formik.errors.titleHeadline)}
                    fullWidth
                    helperText={formik.touched.titleHeadline && formik.errors.titleHeadline}
                    label="Name *"
                    {...formik.getFieldProps("titleHeadline")}
                    sx={{ backgroundColor: '#ffffff' }}
                />

                <QuillEditor
                    onChange={(value: string): void => {
                        formik.setFieldValue('abstract', value);
                    }}
                    placeholder="Description"
                    sx={{ height: 170, backgroundColor: '#ffffff' }}
                    value={formik.values.abstract}
                />
            </Stack>
        </>

    );
};