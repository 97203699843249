import type { FC } from 'react';
import User01Icon from '@untitled-ui/icons-react/build/esm/User01';
import { Avatar, Box, ButtonBase, SvgIcon } from '@mui/material';
import { usePopover } from 'hooks/use-popover';
import { AccountPopover } from './account-popover';
import { useAuth } from 'hooks/use-auth';

export const AccountButton: FC = () => {
    const { peerdwebUser } = useAuth();
    const popover = usePopover<HTMLButtonElement>();

    return (
        <>
            <Box sx={{ alignItems: 'center', display: 'flex', borderWidth: 2, borderStyle: 'solid', borderColor: 'divider', height: 40, width: 40, borderRadius: '50%' }}
                component={ButtonBase} onClick={popover.handleOpen} ref={popover.anchorRef}>
                <Avatar sx={{ height: 32, width: 32 }} src={peerdwebUser?.imageUrl}>
                    <SvgIcon>
                        <User01Icon />
                    </SvgIcon>
                </Avatar>
            </Box>

            <AccountPopover anchorEl={popover.anchorRef.current} onClose={popover.handleClose} open={popover.open} />
        </>
    );
};
